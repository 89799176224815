import { combineReducers, Reducer } from 'redux'

import userSession from './user-session/reducer'
import { IState } from './user-session/typings'

export default function reducer(): Reducer<IState> {
    return combineReducers({
        user: userSession
    })
}
