import { IRouteElement } from 'typings/route-object'
import { PermissionType } from '../services/oauth/constants'
import { createRoutePaths } from './route-util'

const routes: IRouteElement[] = [
    {
        auth: false,
        children: null,
        componentPath: 'login',
        path: '/',
        permissions: null
    },
    {
        auth: false,
        children: null,
        componentPath: 'status',
        path: '/status',
        permissions: null
    },
    {
        auth: false,
        children: [
            {
                auth: false,
                children: null,
                componentPath: 'password-reset',
                name: 'INIT_RESET',
                path: '/init-reset',
                permissions: null
            },
            {
                auth: false,
                children: null,
                componentPath: 'password-reset',
                name: 'RESET',
                path: '/reset/:token',
                permissions: null
            }
        ],
        componentPath: null,
        name: 'PASSWORD',
        path: '/password',
        permissions: null
    },
    {
        auth: true,
        children: null,
        componentPath: 'logout',
        name: 'logout',
        navigationElement: false,
        path: '/logout',
        permissions: null
    },
    {
        auth: true,
        children: null,
        componentPath: 'profile',
        name: 'PROFILE',
        navigationElement: false,
        path: '/profile',
        permissions: null
    },
    // Keep the dashboards sorted exactly this way, it is important.
    {
        auth: true,
        children: null,
        componentPath: 'admin-dashboard',
        name: 'DASHBOARD',
        navigationElement: true,
        navigationLogo: null,
        path: '/dashboard',
        permissions: {
            external_flights: PermissionType.Read,
            rtb_flights: PermissionType.Read
        }
    },
    {
        auth: true,
        children: null,
        componentPath: 'dsp-advertiser-dashboard',
        name: 'DSP_ADVERTISER_DASHBOARD',
        navigationElement: false,
        navigationLogo: null,
        path: '/dashboard',
        permissions: {
            advertisers: PermissionType.Read,
            ads: PermissionType.Read,
            advertiser_campaigns: PermissionType.Read,
            target_networks: PermissionType.Read
        }
    },
    {
        auth: true,
        children: null,
        componentPath: 'publisher-dashboard',
        name: 'DASHBOARD',
        navigationElement: true,
        navigationLogo: null,
        path: '/dashboard',
        permissions: {
            publishers: PermissionType.Read,
            external_programs: PermissionType.Read,
            external_transactions: PermissionType.Read,
            magictags: PermissionType.Read,
            sites: PermissionType.Read
        }
    },
    {
        auth: true,
        children: null,
        componentPath: 'reporting-advertiser-dashboard',
        name: 'REPORTING_ADVERTISER_DASHBOARD',
        navigationElement: false,
        navigationLogo: null,
        path: '/dashboard',
        permissions: {
            ssp_statistics: PermissionType.Read,
            ssp_statistic_hourlies: PermissionType.Read,
            ssp_statistic_dailies: PermissionType.Read
        }
    },
    // administration
    {
        auth: true,
        children: [
            // external advertiser
            {
                auth: true,
                children: [
                    {
                        auth: true,
                        children: null,
                        componentPath: 'external-advertisers/scenes/external-advertiser',
                        name: 'ADVERTISER',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/create',
                        permissions: {
                            advertisers: PermissionType.Write,
                            companies: PermissionType.Read,
                            external_contracts: PermissionType.Read,
                            programs: PermissionType.Read
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'external-advertisers/scenes/external-advertiser',
                        name: 'ADVERTISERS',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/edit/:id',
                        permissions: {
                            advertisers: PermissionType.Write,
                            companies: PermissionType.Read,
                            external_contracts: PermissionType.Read,
                            programs: PermissionType.Read
                        }
                    }
                ],
                componentPath: 'external-advertisers',
                name: 'ADVERTISER',
                navigationElement: true,
                navigationLogo: null,
                path: '/advertisers',
                permissions: {
                    advertisers: PermissionType.Read,
                    companies: PermissionType.Read,
                    programs: PermissionType.Read
                }
            },
            // external programs
            {
                auth: true,
                children: [
                    {
                        auth: true,
                        children: null,
                        componentPath: 'external-programs/scenes/external-program',
                        name: 'PROGRAM',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/edit/:id',
                        permissions: {
                            categories: PermissionType.Read,
                            external_accounts: PermissionType.Read,
                            external_programs: PermissionType.Write,
                            external_sources: PermissionType.Read,
                            leagues: PermissionType.Read,
                            logos: PermissionType.Write,
                            programs: PermissionType.Write
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'external-programs/scenes/external-program',
                        name: 'PROGRAM',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/create',
                        permissions: {
                            categories: PermissionType.Read,
                            external_programs: PermissionType.Write,
                            external_sources: PermissionType.Read,
                            leagues: PermissionType.Read,
                            programs: PermissionType.Read
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'program-audience',
                        name: 'PROGRAM_AUDIENCE',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/audience/:program',
                        permissions: {
                            categories: PermissionType.Read,
                            external_accounts: PermissionType.Read,
                            external_programs: PermissionType.Write,
                            external_sources: PermissionType.Read,
                            leagues: PermissionType.Read,
                            logos: PermissionType.Write,
                            programs: PermissionType.Write
                        }
                    }
                ],
                componentPath: 'external-programs',
                name: 'PROGRAMS',
                navigationElement: true,
                navigationLogo: null,
                path: '/programs',
                permissions: {
                    ads: PermissionType.Read,
                    advertisers: PermissionType.Read,
                    categories: PermissionType.Read,
                    external_programs: PermissionType.Read,
                    external_sources: PermissionType.Read,
                    program_audience_pools: PermissionType.Read,
                    programs: PermissionType.Read
                }
            },
            // external flights
            {
                auth: true,
                children: null,
                componentPath: 'external-flights',
                name: 'FLIGHTS',
                navigationElement: true,
                navigationLogo: null,
                path: '/flights',
                permissions: {
                    ad_containers: PermissionType.Read,
                    ads: PermissionType.Read,
                    external_ad_pools: PermissionType.Read,
                    external_flights: PermissionType.Read,
                    external_sources: PermissionType.Read,
                    flights: PermissionType.Read
                }
            },
            // external ads
            {
                auth: true,
                children: [
                    {
                        auth: true,
                        children: null,
                        componentPath: 'external-ads/scenes/html-ad',
                        navigationElement: false,
                        path: '/html-ad/edit/:id',
                        permissions: {
                            ads: PermissionType.Write,
                            advanced_ads: PermissionType.Write,
                            categories: PermissionType.Read,
                            dimensions: PermissionType.Write,
                            external_accounts: PermissionType.Read,
                            html_codes: PermissionType.Write,
                            html_trackings: PermissionType.Write,
                            impression_tracking_urls: PermissionType.Write,
                            landing_page_urls: PermissionType.Write,
                            programs: PermissionType.Read,
                            trackings: PermissionType.Write
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'external-ads/scenes/image-ad',
                        navigationElement: false,
                        path: '/image-ad/edit/:id',
                        permissions: {
                            ads: PermissionType.Write,
                            advanced_ads: PermissionType.Write,
                            categories: PermissionType.Read,
                            click_links: PermissionType.Write,
                            dimensions: PermissionType.Write,
                            external_accounts: PermissionType.Read,
                            image_trackings: PermissionType.Write,
                            image_urls: PermissionType.Write,
                            impression_tracking_urls: PermissionType.Write,
                            landing_page_urls: PermissionType.Write,
                            programs: PermissionType.Read,
                            trackings: PermissionType.Write
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'external-ads/scenes/native-ad',
                        navigationElement: false,
                        path: '/native-ad/edit/:id',
                        permissions: {
                            ads: PermissionType.Write,
                            advanced_ads: PermissionType.Write,
                            advanced_native_ads: PermissionType.Write,
                            categories: PermissionType.Read,
                            click_links: PermissionType.Write,
                            dimensions: PermissionType.Write,
                            external_accounts: PermissionType.Read,
                            image_urls: PermissionType.Write,
                            impression_tracking_urls: PermissionType.Write,
                            landing_page_urls: PermissionType.Write,
                            native_trackings: PermissionType.Write,
                            programs: PermissionType.Read,
                            trackings: PermissionType.Write
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'external-ads/scenes/tracking-ad',
                        navigationElement: false,
                        path: '/tracking-ad/edit/:id',
                        permissions: {
                            ads: PermissionType.Write,
                            advanced_ads: PermissionType.Write,
                            categories: PermissionType.Read,
                            click_links: PermissionType.Write,
                            external_accounts: PermissionType.Read,
                            impression_tracking_urls: PermissionType.Write,
                            tracking_ads: PermissionType.Write,
                            programs: PermissionType.Read,
                            trackings: PermissionType.Write
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'external-ads/scenes/html-ad',
                        navigationElement: false,
                        path: '/html-ad/create',
                        permissions: {
                            ads: PermissionType.Write,
                            advanced_ads: PermissionType.Write,
                            categories: PermissionType.Read,
                            dimensions: PermissionType.Write,
                            external_accounts: PermissionType.Read,
                            html_codes: PermissionType.Write,
                            html_trackings: PermissionType.Write,
                            impression_tracking_urls: PermissionType.Write,
                            landing_page_urls: PermissionType.Write,
                            programs: PermissionType.Read,
                            trackings: PermissionType.Write
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'external-ads/scenes/image-ad',
                        navigationElement: false,
                        path: '/image-ad/create',
                        permissions: {
                            ads: PermissionType.Write,
                            advanced_ads: PermissionType.Write,
                            categories: PermissionType.Read,
                            click_links: PermissionType.Write,
                            dimensions: PermissionType.Write,
                            external_accounts: PermissionType.Read,
                            image_trackings: PermissionType.Write,
                            image_urls: PermissionType.Write,
                            impression_tracking_urls: PermissionType.Write,
                            landing_page_urls: PermissionType.Write,
                            programs: PermissionType.Read,
                            trackings: PermissionType.Write
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'external-ads/scenes/native-ad',
                        navigationElement: false,
                        path: '/native-ad/create',
                        permissions: {
                            ads: PermissionType.Write,
                            advanced_ads: PermissionType.Write,
                            advanced_native_ads: PermissionType.Write,
                            categories: PermissionType.Read,
                            click_links: PermissionType.Write,
                            dimensions: PermissionType.Write,
                            external_accounts: PermissionType.Read,
                            image_urls: PermissionType.Write,
                            impression_tracking_urls: PermissionType.Write,
                            landing_page_urls: PermissionType.Write,
                            native_trackings: PermissionType.Write,
                            programs: PermissionType.Read,
                            trackings: PermissionType.Write
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'external-ads/scenes/tracking-ad',
                        navigationElement: false,
                        path: '/tracking-ad/create',
                        permissions: {
                            ads: PermissionType.Write,
                            advanced_ads: PermissionType.Write,
                            categories: PermissionType.Read,
                            click_links: PermissionType.Write,
                            external_accounts: PermissionType.Read,
                            impression_tracking_urls: PermissionType.Write,
                            tracking_ads: PermissionType.Write,
                            programs: PermissionType.Read,
                            trackings: PermissionType.Write
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'external-ads',
                        name: 'ADS',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/program/:id',
                        permissions: {
                            ads: PermissionType.Read,
                            advanced_ads: PermissionType.Read,
                            channels: PermissionType.Read,
                            dimensions: PermissionType.Read,
                            display_types: PermissionType.Read,
                            external_accounts: PermissionType.Read,
                            html_codes: PermissionType.Read,
                            html_trackings: PermissionType.Read,
                            image_trackings: PermissionType.Read,
                            image_urls: PermissionType.Read,
                            native_trackings: PermissionType.Read,
                            trackings: PermissionType.Read
                        }
                    },
                    {
                        auth: true,
                        children: [],
                        componentPath: 'ads',
                        name: 'ADS',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/:type/:id',
                        permissions: {
                            ads: PermissionType.Read,
                            advanced_ads: PermissionType.Read,
                            advanced_native_ads: PermissionType.Read,
                            categories: PermissionType.Read,
                            click_links: PermissionType.Read,
                            dimensions: PermissionType.Read,
                            external_accounts: PermissionType.Read,
                            html_codes: PermissionType.Read,
                            html_trackings: PermissionType.Read,
                            image_trackings: PermissionType.Read,
                            image_urls: PermissionType.Read,
                            impression_tracking_urls: PermissionType.Read,
                            landing_page_urls: PermissionType.Read,
                            native_trackings: PermissionType.Read,
                            programs: PermissionType.Read,
                            trackings: PermissionType.Read
                        }
                    }
                ],
                componentPath: 'external-ads',
                name: 'ADS',
                navigationElement: true,
                navigationLogo: null,
                path: '/ads',
                permissions: {
                    ads: PermissionType.Read,
                    advanced_ads: PermissionType.Read,
                    channels: PermissionType.Read,
                    dimensions: PermissionType.Read,
                    display_types: PermissionType.Read,
                    external_accounts: PermissionType.Read,
                    html_codes: PermissionType.Read,
                    html_trackings: PermissionType.Read,
                    image_trackings: PermissionType.Read,
                    image_urls: PermissionType.Read,
                    native_trackings: PermissionType.Read,
                    trackings: PermissionType.Read
                }
            },
            // ad-pool
            {
                auth: true,
                children: [
                    {
                        auth: true,
                        children: null,
                        componentPath: 'ad-pools/scenes/ad-pool',
                        name: 'AD_POOLS',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/create',
                        permissions: {
                            ad_pool_ranks: PermissionType.Write,
                            ad_pools: PermissionType.Write,
                            ads: PermissionType.Read,
                            categories: PermissionType.Read,
                            external_programs: PermissionType.Read,
                            item_lists: PermissionType.Read,
                            leagues: PermissionType.Read,
                            programs: PermissionType.Read,
                            rotation_types: PermissionType.Read
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'ad-pools/scenes/ad-pool',
                        name: 'AD_POOLS',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/edit/:id',
                        permissions: {
                            ad_pool_ranks: PermissionType.Delete,
                            ad_pools: PermissionType.Write,
                            ads: PermissionType.Read,
                            categories: PermissionType.Read,
                            external_programs: PermissionType.Read,
                            item_lists: PermissionType.Read,
                            leagues: PermissionType.Read,
                            programs: PermissionType.Read,
                            rotation_types: PermissionType.Read
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'ad-pools',
                        name: 'AD_POOLS',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/program/:programId',
                        permissions: {
                            ad_pool_ranks: PermissionType.Read,
                            ad_pools: PermissionType.Read,
                            ads: PermissionType.Read,
                            categories: PermissionType.Read,
                            dimensions: PermissionType.Read,
                            leagues: PermissionType.Read,
                            programs: PermissionType.Read,
                            rotation_types: PermissionType.Read
                        }
                    }
                ],
                componentPath: 'ad-pools',
                name: 'AD_POOL',
                navigationElement: true,
                navigationLogo: null,
                path: '/ad-pools',
                permissions: {
                    ad_pool_ranks: PermissionType.Read,
                    ad_pools: PermissionType.Read,
                    ads: PermissionType.Read,
                    categories: PermissionType.Read,
                    dimensions: PermissionType.Read,
                    leagues: PermissionType.Read,
                    programs: PermissionType.Read,
                    rotation_types: PermissionType.Read
                }
            },
            // adcode-generator
            {
                auth: true,
                children: null,
                componentPath: 'adcode-generator',
                name: 'ADCODE_GENERATOR',
                navigationElement: true,
                navigationLogo: null,
                path: '/adcode-generator',
                permissions: {
                    ad_pool_ranks: PermissionType.Read,
                    ad_pools: PermissionType.Read,
                    channels: PermissionType.Read,
                    dimensions: PermissionType.Read,
                    environments: PermissionType.Read,
                    magictag_module_reachs: PermissionType.Read,
                    magictag_modules: PermissionType.Read,
                    programs: PermissionType.Read,
                    rtb_flights: PermissionType.Read
                }
            },
            // Tools
            {
                auth: true,
                children: [
                    {
                        auth: true,
                        children: [
                            {
                                auth: true,
                                children: null,
                                componentPath: 'campaign-id/encrypt',
                                name: 'ENCRYPT',
                                navigationElement: true,
                                navigationLogo: null,
                                path: '/encrypt',
                                permissions: {
                                    ads: PermissionType.Read,
                                    channels: PermissionType.Read,
                                    display_types: PermissionType.Read,
                                    environments: PermissionType.Read,
                                    external_transaction_types: PermissionType.Read,
                                    programs: PermissionType.Read,
                                    rtb_flights: PermissionType.Read,
                                    sites: PermissionType.Read,
                                    transaction_types: PermissionType.Read
                                }
                            },
                            {
                                auth: true,
                                children: null,
                                componentPath: 'campaign-id/decrypt',
                                name: 'DECRYPT',
                                navigationElement: true,
                                navigationLogo: null,
                                path: '/decrypt',
                                permissions: {
                                    ads: PermissionType.Read,
                                    channels: PermissionType.Read,
                                    display_types: PermissionType.Read,
                                    environments: PermissionType.Read,
                                    external_transaction_types: PermissionType.Read,
                                    programs: PermissionType.Read,
                                    rtb_flights: PermissionType.Read,
                                    sites: PermissionType.Read,
                                    transaction_types: PermissionType.Read
                                }
                            }
                        ],
                        componentPath: null,
                        name: 'CAMPAIGN_ID_GENERATOR',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/campaign-id',
                        permissions: null
                    },
                    {
                        auth: true,
                        children: [
                            {
                                auth: true,
                                children: [],
                                componentPath: 'mycashbacks/scenes/contracts/scenes/contract',
                                name: 'MYCASHBACK_CONTRACT',
                                path: '/:externalProgramId/contracts/create',
                                permissions: {
                                    enduser_contracts: PermissionType.Write,
                                    enduser_shares: PermissionType.Write,
                                    external_programs: PermissionType.Read,
                                    external_share_types: PermissionType.Read,
                                    senders: PermissionType.Read
                                }
                            },
                            {
                                auth: true,
                                children: [],
                                componentPath: 'mycashbacks/scenes/contracts/scenes/contract',
                                name: 'MYCASHBACK_CONTRACT',
                                path: '/:externalProgramId/contracts/edit/:id',
                                permissions: {
                                    enduser_contracts: PermissionType.Write,
                                    enduser_shares: PermissionType.Write,
                                    external_programs: PermissionType.Read,
                                    external_share_types: PermissionType.Read,
                                    senders: PermissionType.Read
                                }
                            },
                            {
                                auth: true,
                                children: [],
                                componentPath: 'mycashbacks/scenes/contracts',
                                name: 'MYCASHBACK_CONTRACTS',
                                path: '/:externalProgramId/contracts',
                                permissions: {
                                    enduser_contracts: PermissionType.Read,
                                    external_share_types: PermissionType.Read
                                }
                            }
                        ],
                        componentPath: 'mycashbacks',
                        name: 'MYCASHBACKS_PROGRAMS',
                        path: '/mycashbacks',
                        permissions: {
                            enduser_contracts: PermissionType.Read,
                            external_programs: PermissionType.Read,
                            external_share_types: PermissionType.Read
                        }
                    },
                    {
                        auth: true,
                        children: [
                            {
                                auth: true,
                                children: null,
                                componentPath: 'categories/scenes/category',
                                name: 'CATEGORIES',
                                navigationElement: false,
                                navigationLogo: null,
                                path: '/edit/:id',
                                permissions: {
                                    users: PermissionType.Delete
                                }
                            },
                            {
                                auth: true,
                                children: null,
                                componentPath: 'categories/scenes/category',
                                name: 'CATEGORIES',
                                navigationElement: false,
                                navigationLogo: null,
                                path: '/create',
                                permissions: {
                                    users: PermissionType.Delete
                                }
                            }
                        ],
                        componentPath: 'categories',
                        name: 'CATEGORY_MANAGEMENT',
                        path: '/categories',
                        permissions: {
                            users: PermissionType.Delete
                        }
                    }
                ],
                componentPath: 'tools',
                name: 'TOOLS',
                navigationElement: true,
                navigationLogo: null,
                path: '/tools',
                permissions: {
                    ad_pool_ranks: PermissionType.Read,
                    ad_pools: PermissionType.Read,
                    ads: PermissionType.Read,
                    channels: PermissionType.Read,
                    dimensions: PermissionType.Read,
                    display_types: PermissionType.Read,
                    environments: PermissionType.Read,
                    external_transaction_types: PermissionType.Read,
                    magictag_module_reachs: PermissionType.Read,
                    magictag_modules: PermissionType.Read,
                    programs: PermissionType.Read,
                    rtb_flights: PermissionType.Read,
                    sites: PermissionType.Read,
                    transaction_types: PermissionType.Read
                }
            }
        ],
        componentPath: null,
        name: 'ADMINISTRATION',
        navigationElement: true,
        navigationLogo: null,
        path: '/admin',
        permissions: {
            ads: PermissionType.Read,
            advanced_ads: PermissionType.Read,
            channels: PermissionType.Read,
            dimensions: PermissionType.Read,
            display_types: PermissionType.Read,
            external_accounts: PermissionType.Read,
            html_codes: PermissionType.Read,
            html_trackings: PermissionType.Read,
            image_trackings: PermissionType.Read,
            image_urls: PermissionType.Read,
            native_trackings: PermissionType.Read,
            trackings: PermissionType.Read,
            advertisers: PermissionType.Read,
            companies: PermissionType.Read,
            programs: PermissionType.Read
        }
    },
    // dsp
    {
        auth: true,
        children: [
            // advertisers
            {
                auth: true,
                children: [
                    {
                        auth: true,
                        children: null,
                        componentPath: 'advertisers/scenes/advertiser',
                        name: 'ADVERTISER',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/create',
                        permissions: {
                            advertisers: PermissionType.Write,
                            companies: PermissionType.Read,
                            external_contracts: PermissionType.Read,
                            targeting_url_lists: PermissionType.Read
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'advertisers/scenes/advertiser',
                        name: 'ADVERTISER',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/edit/:id',
                        permissions: {
                            advertisers: PermissionType.Write,
                            companies: PermissionType.Read,
                            external_contracts: PermissionType.Read,
                            targeting_url_lists: PermissionType.Read
                        }
                    },
                    {
                        auth: true,
                        children: [
                            {
                                auth: true,
                                children: null,
                                componentPath: 'advertisers/scenes/contracts/scenes/contract',
                                name: 'CONTRACTS',
                                navigationElement: false,
                                navigationLogo: null,
                                path: '/create',
                                permissions: {
                                    channels: PermissionType.Read,
                                    display_types: PermissionType.Read,
                                    environments: PermissionType.Read,
                                    external_contracts: PermissionType.Write,
                                    external_event_types: PermissionType.Read,
                                    external_share_types: PermissionType.Read,
                                    external_shares: PermissionType.Write
                                }
                            }
                        ],
                        componentPath: 'advertisers/scenes/contracts',
                        name: 'CONTRACTS',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/:advertiserId/contracts',
                        permissions: {
                            channels: PermissionType.Read,
                            display_types: PermissionType.Read,
                            environments: PermissionType.Read,
                            external_event_types: PermissionType.Read,
                            external_share_types: PermissionType.Read,
                            external_shares: PermissionType.Read
                        }
                    }
                ],
                componentPath: 'advertisers',
                name: 'ADVERTISER',
                navigationElement: true,
                navigationLogo: null,
                path: '/advertisers',
                permissions: {
                    advertisers: PermissionType.Read
                }
            },
            // programs
            {
                auth: true,
                children: [
                    {
                        auth: true,
                        children: null,
                        componentPath: 'programs/scenes/program',
                        navigationElement: false,
                        path: '/create',
                        permissions: {
                            advertisers: PermissionType.Read,
                            categories: PermissionType.Read,
                            dsp_programs: PermissionType.Write,
                            programs: PermissionType.Write
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'programs/scenes/program',
                        navigationElement: false,
                        path: '/edit/:id',
                        permissions: {
                            advertisers: PermissionType.Read,
                            categories: PermissionType.Read,
                            dsp_programs: PermissionType.Write,
                            programs: PermissionType.Write
                        }
                    }
                ],
                componentPath: 'programs',
                name: 'PROGRAMS',
                navigationElement: true,
                navigationLogo: null,
                path: '/programs',
                permissions: {
                    dsp_programs: PermissionType.Read,
                    programs: PermissionType.Read
                }
            },
            // campaigns
            {
                auth: true,
                children: [
                    {
                        auth: true,
                        children: null,
                        componentPath: 'campaigns/scenes/campaign',
                        navigationElement: false,
                        path: '/create',
                        permissions: {
                            a_campaign_categorisations: PermissionType.Read,
                            a_campaign_f_categorisations: PermissionType.Read,
                            ads: PermissionType.Read,
                            advertiser_campaigns: PermissionType.Write,
                            advertisers: PermissionType.Read,
                            adx_geo_regions: PermissionType.Read,
                            adx_publisher_verticals: PermissionType.Read,
                            adx_site_lists: PermissionType.Read,
                            audience_rule_lists: PermissionType.Read,
                            audience_rules: PermissionType.Read,
                            audience_types: PermissionType.Read,
                            bid_price_types: PermissionType.Read,
                            browsers: PermissionType.Read,
                            categories: PermissionType.Read,
                            channels: PermissionType.Read,
                            content_labels: PermissionType.Read,
                            deals: PermissionType.Read,
                            dimensions: PermissionType.Read,
                            dsp_ads: PermissionType.Read,
                            dsp_programs: PermissionType.Read,
                            environments: PermissionType.Read,
                            foreign_categories: PermissionType.Read,
                            frequency_caps: PermissionType.Read,
                            languages: PermissionType.Read,
                            networks: PermissionType.Read,
                            operating_systems: PermissionType.Read,
                            pace_types: PermissionType.Read,
                            programs: PermissionType.Read,
                            slot_visibilities: PermissionType.Read,
                            target_networks: PermissionType.Read,
                            targeting_url_lists: PermissionType.Read
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'campaigns/scenes/campaign',
                        navigationElement: false,
                        path: '/edit/:id',
                        permissions: {
                            a_campaign_categorisations: PermissionType.Read,
                            a_campaign_f_categorisations: PermissionType.Read,
                            ads: PermissionType.Read,
                            advertiser_campaigns: PermissionType.Write,
                            advertisers: PermissionType.Read,
                            adx_geo_regions: PermissionType.Read,
                            adx_publisher_verticals: PermissionType.Read,
                            adx_site_lists: PermissionType.Read,
                            audience_rule_lists: PermissionType.Read,
                            audience_rules: PermissionType.Read,
                            audience_types: PermissionType.Read,
                            bid_price_types: PermissionType.Read,
                            browsers: PermissionType.Read,
                            categories: PermissionType.Read,
                            channels: PermissionType.Read,
                            content_labels: PermissionType.Read,
                            deals: PermissionType.Read,
                            dimensions: PermissionType.Read,
                            dsp_ads: PermissionType.Read,
                            dsp_programs: PermissionType.Read,
                            environments: PermissionType.Read,
                            foreign_categories: PermissionType.Read,
                            frequency_caps: PermissionType.Read,
                            languages: PermissionType.Read,
                            networks: PermissionType.Read,
                            operating_systems: PermissionType.Read,
                            pace_types: PermissionType.Read,
                            programs: PermissionType.Read,
                            slot_visibilities: PermissionType.Read,
                            target_networks: PermissionType.Read,
                            targeting_url_lists: PermissionType.Read
                        }
                    }
                ],
                componentPath: 'campaigns',
                name: 'CAMPAIGNS',
                navigationElement: true,
                path: '/campaigns',
                permissions: {
                    ads: PermissionType.Read,
                    advertiser_campaigns: PermissionType.Read,
                    target_networks: PermissionType.Read
                }
            },
            // flights
            {
                auth: true,
                children: [
                    {
                        auth: true,
                        children: null,
                        componentPath: 'rtb-flights/scenes/rtb-flight',
                        navigationElement: false,
                        path: '/create',
                        permissions: {
                            ad_containers: PermissionType.Write,
                            ads: PermissionType.Read,
                            advertiser_campaigns: PermissionType.Read,
                            advertisers: PermissionType.Read,
                            adx_geo_regions: PermissionType.Read,
                            adx_publisher_verticals: PermissionType.Read,
                            adx_site_lists: PermissionType.Read,
                            audience_rule_lists: PermissionType.Read,
                            audience_rules: PermissionType.Read,
                            audience_types: PermissionType.Read,
                            bid_price_types: PermissionType.Read,
                            browsers: PermissionType.Read,
                            categories: PermissionType.Read,
                            channels: PermissionType.Read,
                            content_labels: PermissionType.Read,
                            deals: PermissionType.Read,
                            dimensions: PermissionType.Read,
                            dsp_ads: PermissionType.Read,
                            environments: PermissionType.Read,
                            flights: PermissionType.Write,
                            foreign_categories: PermissionType.Read,
                            frequency_caps: PermissionType.Read,
                            languages: PermissionType.Read,
                            networks: PermissionType.Read,
                            operating_systems: PermissionType.Read,
                            pace_types: PermissionType.Read,
                            rtb_flight_categorisations: PermissionType.Read,
                            rtb_flight_f_categorisations: PermissionType.Read,
                            rtb_flights: PermissionType.Write,
                            slot_visibilities: PermissionType.Read,
                            target_networks: PermissionType.Read,
                            targeting_url_lists: PermissionType.Read
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'rtb-flights/scenes/rtb-flight',
                        navigationElement: false,
                        path: '/edit/:id',
                        permissions: {
                            ad_containers: PermissionType.Write,
                            ads: PermissionType.Read,
                            advertiser_campaigns: PermissionType.Read,
                            advertisers: PermissionType.Read,
                            adx_geo_regions: PermissionType.Read,
                            adx_publisher_verticals: PermissionType.Read,
                            adx_site_lists: PermissionType.Read,
                            audience_rule_lists: PermissionType.Read,
                            audience_rules: PermissionType.Read,
                            audience_types: PermissionType.Read,
                            bid_price_types: PermissionType.Read,
                            browsers: PermissionType.Read,
                            categories: PermissionType.Read,
                            channels: PermissionType.Read,
                            content_labels: PermissionType.Read,
                            deals: PermissionType.Read,
                            dimensions: PermissionType.Read,
                            dsp_ads: PermissionType.Read,
                            environments: PermissionType.Read,
                            flights: PermissionType.Write,
                            foreign_categories: PermissionType.Read,
                            frequency_caps: PermissionType.Read,
                            languages: PermissionType.Read,
                            networks: PermissionType.Read,
                            operating_systems: PermissionType.Read,
                            pace_types: PermissionType.Read,
                            rtb_flight_categorisations: PermissionType.Read,
                            rtb_flight_f_categorisations: PermissionType.Read,
                            rtb_flights: PermissionType.Write,
                            slot_visibilities: PermissionType.Read,
                            target_networks: PermissionType.Read,
                            targeting_url_lists: PermissionType.Read
                        }
                    }
                ],
                componentPath: 'rtb-flights',
                name: 'FLIGHTS',
                navigationElement: true,
                navigationLogo: null,
                path: '/flights',
                permissions: {
                    ad_containers: PermissionType.Read,
                    ads: PermissionType.Read,
                    advertiser_campaigns: PermissionType.Read,
                    advertisers: PermissionType.Read,
                    flights: PermissionType.Read,
                    programs: PermissionType.Read,
                    rtb_flights: PermissionType.Read,
                    target_networks: PermissionType.Read
                }
            },
            // dsp - ads
            {
                auth: true,
                children: [
                    {
                        auth: true,
                        children: null,
                        componentPath: 'dsp-ads/scenes/html-ad',
                        navigationElement: false,
                        path: '/html-ad/edit/:id',
                        permissions: {
                            ads: PermissionType.Write,
                            adx_creatives: PermissionType.Write,
                            categories: PermissionType.Read,
                            dimensions: PermissionType.Write,
                            disapproval_reasons: PermissionType.Read,
                            dsp_ads: PermissionType.Write,
                            impression_tracking_urls: PermissionType.Write,
                            landing_page_urls: PermissionType.Write,
                            open_auction_status: PermissionType.Read,
                            programs: PermissionType.Read
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'dsp-ads/scenes/image-ad',
                        navigationElement: false,
                        path: '/image-ad/edit/:id',
                        permissions: {
                            ads: PermissionType.Write,
                            adx_creatives: PermissionType.Write,
                            categories: PermissionType.Read,
                            dimensions: PermissionType.Write,
                            disapproval_reasons: PermissionType.Read,
                            dsp_ads: PermissionType.Write,
                            impression_tracking_urls: PermissionType.Write,
                            landing_page_urls: PermissionType.Write,
                            open_auction_status: PermissionType.Read,
                            programs: PermissionType.Read
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'dsp-ads/scenes/native-ad',
                        navigationElement: false,
                        path: '/native-ad/edit/:id',
                        permissions: {
                            ads: PermissionType.Write,
                            adx_creatives: PermissionType.Write,
                            categories: PermissionType.Read,
                            dimensions: PermissionType.Write,
                            disapproval_reasons: PermissionType.Read,
                            dsp_ads: PermissionType.Write,
                            impression_tracking_urls: PermissionType.Write,
                            landing_page_urls: PermissionType.Write,
                            native_ads: PermissionType.Write,
                            networks: PermissionType.Read,
                            open_auction_status: PermissionType.Read,
                            programs: PermissionType.Read
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'dsp-ads/scenes/html-ad',
                        navigationElement: false,
                        path: '/html-ad/create',
                        permissions: {
                            ads: PermissionType.Write,
                            adx_creatives: PermissionType.Write,
                            categories: PermissionType.Read,
                            dimensions: PermissionType.Write,
                            disapproval_reasons: PermissionType.Read,
                            dsp_ads: PermissionType.Write,
                            impression_tracking_urls: PermissionType.Write,
                            landing_page_urls: PermissionType.Write,
                            open_auction_status: PermissionType.Read,
                            programs: PermissionType.Read
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'dsp-ads/scenes/image-ad',
                        navigationElement: false,
                        path: '/image-ad/create',
                        permissions: {
                            ads: PermissionType.Write,
                            adx_creatives: PermissionType.Write,
                            categories: PermissionType.Read,
                            dimensions: PermissionType.Write,
                            disapproval_reasons: PermissionType.Read,
                            dsp_ads: PermissionType.Write,
                            impression_tracking_urls: PermissionType.Write,
                            landing_page_urls: PermissionType.Write,
                            open_auction_status: PermissionType.Read,
                            programs: PermissionType.Read
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'dsp-ads/scenes/native-ad',
                        navigationElement: false,
                        path: '/native-ad/create',
                        permissions: {
                            ads: PermissionType.Write,
                            adx_creatives: PermissionType.Write,
                            categories: PermissionType.Read,
                            dimensions: PermissionType.Write,
                            disapproval_reasons: PermissionType.Read,
                            dsp_ads: PermissionType.Write,
                            impression_tracking_urls: PermissionType.Write,
                            landing_page_urls: PermissionType.Write,
                            native_ads: PermissionType.Write,
                            networks: PermissionType.Read,
                            open_auction_status: PermissionType.Read,
                            programs: PermissionType.Read
                        }
                    },
                    {
                        auth: true,
                        children: [],
                        componentPath: 'dsp-ads',
                        name: 'ADS',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/:type/:id',
                        permissions: {
                            ads: PermissionType.Read,
                            advertiser_campaigns: PermissionType.Read,
                            adx_creatives: PermissionType.Read,
                            dimensions: PermissionType.Read,
                            display_types: PermissionType.Read,
                            dsp_ads: PermissionType.Read,
                            open_auction_status: PermissionType.Read
                        }
                    }
                ],
                componentPath: 'dsp-ads',
                name: 'ADS',
                navigationElement: true,
                navigationLogo: null,
                path: '/ads',
                permissions: {
                    ads: PermissionType.Read,
                    advertiser_campaigns: PermissionType.Read,
                    adx_creatives: PermissionType.Read,
                    dimensions: PermissionType.Read,
                    display_types: PermissionType.Read,
                    dsp_ads: PermissionType.Read,
                    open_auction_status: PermissionType.Read
                }
            },
            // dsp statistics
            {
                auth: true,
                children: [
                    {
                        auth: true,
                        children: null,
                        componentPath: 'dsp-statistic',
                        name: 'STATISTIC',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/:type/:resources',
                        permissions: {
                            rtb_campaign_statistics: PermissionType.Read
                        }
                    }
                ],
                componentPath: 'dsp-statistic',
                name: 'STATISTIC',
                navigationElement: true,
                navigationLogo: null,
                path: '/statistics',
                permissions: {
                    rtb_campaign_statistics: PermissionType.Read
                }
            },
            {
                auth: true,
                children: [
                    {
                        auth: true,
                        children: [
                            {
                                auth: true,
                                children: null,
                                componentPath: 'companies/scenes/company',
                                navigationElement: false,
                                path: '/create',
                                permissions: {
                                    addresses: PermissionType.Read,
                                    advertisers: PermissionType.Read,
                                    companies: PermissionType.Write,
                                    contact_addresses: PermissionType.Read,
                                    contacts: PermissionType.Read,
                                    countries: PermissionType.Read,
                                    publishers: PermissionType.Read
                                }
                            },
                            {
                                auth: true,
                                children: null,
                                componentPath: 'companies/scenes/company',
                                navigationElement: false,
                                path: '/edit/:id',
                                permissions: {
                                    addresses: PermissionType.Read,
                                    advertisers: PermissionType.Read,
                                    companies: PermissionType.Write,
                                    contact_addresses: PermissionType.Read,
                                    contacts: PermissionType.Read,
                                    countries: PermissionType.Read,
                                    publishers: PermissionType.Read
                                }
                            }
                        ],
                        componentPath: 'companies',
                        name: 'COMPANIES',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/companies',
                        permissions: {
                            companies: PermissionType.Read
                        }
                    },
                    // deals
                    {
                        auth: true,
                        children: [
                            {
                                auth: true,
                                children: null,
                                componentPath: 'deals/scenes/deal',
                                navigationElement: false,
                                path: '/create',
                                permissions: {
                                    advertiser_campaigns: PermissionType.Read,
                                    advertisers: PermissionType.Read,
                                    deal_types: PermissionType.Read,
                                    deals: PermissionType.Write,
                                    dimensions: PermissionType.Read,
                                    networks: PermissionType.Read
                                }
                            },
                            {
                                auth: true,
                                children: null,
                                componentPath: 'deals/scenes/deal',
                                navigationElement: false,
                                path: '/edit/:id',
                                permissions: {
                                    advertiser_campaigns: PermissionType.Read,
                                    advertisers: PermissionType.Read,
                                    deal_types: PermissionType.Read,
                                    deals: PermissionType.Write,
                                    dimensions: PermissionType.Read,
                                    networks: PermissionType.Read
                                }
                            }
                        ],
                        componentPath: 'deals',
                        name: 'DEALS',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/deals',
                        permissions: {
                            deal_types: PermissionType.Read,
                            deals: PermissionType.Read
                        }
                    },
                    // site lists
                    {
                        auth: true,
                        children: [
                            {
                                auth: true,
                                children: null,
                                componentPath: 'site-lists/scenes/site-list',
                                navigationElement: false,
                                path: '/create',
                                permissions: {
                                    advertisers: PermissionType.Read,
                                    targeting_url_lists: PermissionType.Write,
                                    targeting_urls: PermissionType.Write
                                }
                            },
                            {
                                auth: true,
                                children: null,
                                componentPath: 'site-lists/scenes/site-list',
                                navigationElement: false,
                                path: '/edit/:id',
                                permissions: {
                                    advertisers: PermissionType.Read,
                                    targeting_url_lists: PermissionType.Write,
                                    targeting_urls: PermissionType.Write
                                }
                            }
                        ],
                        componentPath: 'site-lists',
                        name: 'SITE_LISTS',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/site-lists',
                        permissions: {
                            targeting_url_lists: PermissionType.Read,
                            targeting_urls: PermissionType.Read
                        }
                    },
                    // retargeting lists
                    {
                        auth: true,
                        children: [
                            {
                                auth: true,
                                children: null,
                                componentPath: 'retargeting-lists/scenes/retargeting-list',
                                navigationElement: false,
                                path: '/create',
                                permissions: {
                                    advertisers: PermissionType.Read,
                                    audience_rules: PermissionType.Write
                                }
                            },
                            {
                                auth: true,
                                children: null,
                                componentPath: 'retargeting-lists/scenes/retargeting-list',
                                navigationElement: false,
                                path: '/edit/:id',
                                permissions: {
                                    advertisers: PermissionType.Read,
                                    audience_rules: PermissionType.Write
                                }
                            },
                            {
                                auth: true,
                                children: null,
                                componentPath: 'retargeting-lists-audience',
                                name: 'REGTARGETING_LISTS_AUDIENCE',
                                navigationElement: false,
                                path: '/retargeting-lists-audience/:retargeting_list_id',
                                permissions: {
                                    advertisers: PermissionType.Read,
                                    audience_rules: PermissionType.Write
                                }
                            }
                        ],
                        componentPath: 'retargeting-lists',
                        name: 'RETARGETING_LISTS',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/retargeting-lists',
                        permissions: {
                            audience_rule_pools: PermissionType.Read,
                            audience_rules: PermissionType.Read
                        }
                    }
                ],
                componentPath: 'settings',
                name: 'SETTINGS',
                navigationElement: true,
                navigationLogo: null,
                path: '/settings',
                permissions: {
                    advertisers: PermissionType.Read,
                    audience_rule_pools: PermissionType.Read,
                    audience_rules: PermissionType.Read,
                    deal_types: PermissionType.Read,
                    deals: PermissionType.Write,
                    dimensions: PermissionType.Read,
                    networks: PermissionType.Read,
                    targeting_url_lists: PermissionType.Read

                }
            }
        ],
        componentPath: null,
        name: 'DSP',
        navigationElement: true,
        navigationLogo: null,
        path: '/dsp',
        permissions: null
    },
    // publishers
    {
        auth: true,
        children: [
            {
                auth: true,
                children: [
                    {
                        auth: true,
                        children: null,
                        componentPath: 'publishers/scenes/publisher',
                        name: 'PUBLISHERS',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/create',
                        permissions: {
                            addresses: PermissionType.Write,
                            bank_accounts: PermissionType.Write,
                            companies: PermissionType.Read,
                            contact_addresses: PermissionType.Write,
                            contacts: PermissionType.Write,
                            countries: PermissionType.Read,
                            publishers: PermissionType.Write
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'publishers/scenes/publisher',
                        name: 'PUBLISHERS',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/edit/:id',
                        permissions: {
                            addresses: PermissionType.Write,
                            bank_accounts: PermissionType.Write,
                            companies: PermissionType.Read,
                            contact_addresses: PermissionType.Write,
                            contacts: PermissionType.Write,
                            countries: PermissionType.Read,
                            publishers: PermissionType.Write
                        }
                    }
                ],
                componentPath: 'publishers',
                name: 'PUBLISHERS',
                navigationElement: true,
                navigationLogo: null,
                path: '/publishers',
                permissions: {
                    publishers: PermissionType.Read,
                    sites: PermissionType.Read
                }
            },
            {
                auth: true,
                children: [
                    {
                        auth: true,
                        children: [
                            {
                                auth: true,
                                children: null,
                                componentPath: 'sites/scenes/contracts/scenes/contract',
                                name: 'CONTRACTS',
                                navigationElement: false,
                                navigationLogo: null,
                                path: '/create',
                                permissions: {
                                    channels: PermissionType.Read,
                                    publisher_contracts: PermissionType.Write,
                                    publisher_shares: PermissionType.Write,
                                    external_programs: PermissionType.Read
                                }
                            }
                        ],
                        componentPath: 'sites/scenes/contracts',
                        name: 'CONTRACTS',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/:siteId/contracts',
                        permissions: {
                            channels: PermissionType.Read,
                            publisher_contracts: PermissionType.Read,
                            publisher_shares: PermissionType.Read,
                            external_programs: PermissionType.Read
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'sites/scenes/site',
                        name: 'SITES',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/create',
                        permissions: {
                            magictags: PermissionType.Read,
                            publishers: PermissionType.Read,
                            sites: PermissionType.Write
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'sites/scenes/site',
                        name: 'SITES',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/edit/:id',
                        permissions: {
                            magictags: PermissionType.Read,
                            publishers: PermissionType.Read,
                            sites: PermissionType.Write
                        }
                    },
                    {
                        auth: true,
                        children: [],
                        componentPath: 'sites',
                        name: 'SITES',
                        navigationElement: false,
                        navigationLogo: null,
                        path: '/:type/:id',
                        permissions: {
                            magictags: PermissionType.Read,
                            publishers: PermissionType.Read,
                            sites: PermissionType.Read
                        }
                    }
                ],
                componentPath: 'sites',
                name: 'SITES',
                navigationElement: true,
                navigationLogo: null,
                path: '/sites',
                permissions: {
                    publishers: PermissionType.Read,
                    magictags: PermissionType.Read,
                    sites: PermissionType.Read
                }
            },
            // Invoices
            {
                auth: true,
                children: [],
                componentPath: 'invoices',
                name: 'INVOICES',
                navigationElement: true,
                navigationLogo: null,
                path: '/invoices',
                permissions: {
                    invoices: PermissionType.Read
                }
            }
        ],
        componentPath: null,
        name: 'PUBLISHERS',
        navigationElement: true,
        navigationLogo: null,
        path: '/publisher',
        permissions: {
            publishers: PermissionType.Read
        }
    },
    // advanced-tag
    {
        auth: true,
        children: [
            {
                auth: true,
                children: [
                    {
                        auth: true,
                        children: null,
                        componentPath: 'advanced-tag/scenes/tags/scenes/tag',
                        navigationElement: false,
                        path: '/create',
                        permissions: {
                            magictag_module_external_scripts: PermissionType.Read,
                            magictag_module_types: PermissionType.Read,
                            magictag_modules: PermissionType.Write,
                            magictags: PermissionType.Write
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'advanced-tag/scenes/tags/scenes/tag',
                        navigationElement: false,
                        path: '/edit/:id',
                        permissions: {
                            magictag_module_external_scripts: PermissionType.Read,
                            magictag_module_types: PermissionType.Read,
                            magictag_modules: PermissionType.Write,
                            magictags: PermissionType.Write
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'advanced-tag/scenes/tags/scenes/tag/scenes/module',
                        navigationElement: false,
                        path: '/:tagId/module/create',
                        permissions: {
                            magictag_module_dmps: PermissionType.Write,
                            magictag_module_external_scripts: PermissionType.Write,
                            magictag_module_reachs: PermissionType.Write,
                            magictag_module_triggers: PermissionType.Read,
                            magictag_module_types: PermissionType.Read,
                            magictag_modules: PermissionType.Write
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'advanced-tag/scenes/tags/scenes/tag/scenes/module',
                        navigationElement: false,
                        path: '/:tagId/module/edit/:id',
                        permissions: {
                            magictag_module_dmps: PermissionType.Write,
                            magictag_module_external_scripts: PermissionType.Write,
                            magictag_module_reachs: PermissionType.Write,
                            magictag_module_triggers: PermissionType.Read,
                            magictag_module_types: PermissionType.Read,
                            magictag_modules: PermissionType.Write
                        }
                    }
                ],
                componentPath: 'advanced-tag/scenes/tags',
                name: 'ADVANCED_TAGS',
                navigationElement: true,
                path: '/tags',
                permissions: {
                    magictags: PermissionType.Read,
                    magictag_modules: PermissionType.Read
                }
            },
            {
                auth: true,
                children: [
                    {
                        auth: true,
                        children: null,
                        componentPath: 'advanced-tag/scenes/triggers/scenes/trigger',
                        navigationElement: false,
                        path: '/create',
                        permissions: {
                            magictag_module_trigger_parameter_operators: PermissionType.Write,
                            magictag_module_trigger_parameters: PermissionType.Write,
                            magictag_module_trigger_types: PermissionType.Read,
                            magictag_module_triggers: PermissionType.Write,
                            magictag_modules: PermissionType.Read,
                            magictags: PermissionType.Read
                        }
                    },
                    {
                        auth: true,
                        children: null,
                        componentPath: 'advanced-tag/scenes/triggers/scenes/trigger',
                        navigationElement: false,
                        path: '/edit/:id',
                        permissions: {
                            magictag_module_trigger_parameter_operators: PermissionType.Write,
                            magictag_module_trigger_parameters: PermissionType.Write,
                            magictag_module_trigger_types: PermissionType.Read,
                            magictag_module_triggers: PermissionType.Write,
                            magictag_modules: PermissionType.Read,
                            magictags: PermissionType.Read
                        }
                    }
                ],
                componentPath: 'advanced-tag/scenes/triggers',
                name: 'ADVANCED_TAG_TRIGGERS',
                navigationElement: true,
                path: '/triggers',
                permissions: {
                    magictag_module_trigger_types: PermissionType.Read,
                    magictag_module_triggers: PermissionType.Read
                }
            }
        ],
        componentPath: null,
        navigationElement: true,
        path: '/advanced-tag',
        permissions: {
            magictags: PermissionType.Read
        },
        name: 'ADVANCED_TAG'
    },
    // Reporting
    {
        auth: true,
        children: [
            // External Statistics
            {
                auth: true,
                children: null,
                componentPath: 'external-statistic',
                name: 'STATISTIC',
                navigationElement: true,
                navigationLogo: null,
                path: '/statistics',
                permissions: {
                    external_transactions: PermissionType.Read,
                    ssp_statistic_dailies: PermissionType.Read,
                    ssp_statistic_hourlies: PermissionType.Read
                }
            },
            // External-transactions
            {
                auth: true,
                children: null,
                componentPath: 'external-transactions',
                name: 'TRANSACTIONS',
                navigationElement: true,
                navigationLogo: null,
                path: '/transactions',
                permissions: {
                    external_programs: PermissionType.Read,
                    external_transaction_status: PermissionType.Read,
                    external_transaction_types: PermissionType.Read,
                    external_transactions: PermissionType.Read
                }
            }
        ],
        componentPath: null,
        name: 'REPORTING',
        navigationElement: true,
        navigationLogo: null,
        path: '/reporting',
        permissions: null
    },
    // user management
    {
        auth: true,
        children: [
            {
                auth: true,
                children: null,
                componentPath: 'user-management/scenes/roles',
                name: 'ROLES',
                path: '/roles',
                permissions: {
                    users: PermissionType.Write
                }
            },
            {
                auth: true,
                children: null,
                componentPath: 'user-management/scenes/roles',
                name: 'ROLES',
                path: '/roles/:id',
                permissions: {
                    users: PermissionType.Write
                }
            },
            {
                auth: true,
                children: null,
                componentPath: 'user-management/scenes/users',
                name: 'USERS',
                path: '/users',
                permissions: {
                    users: PermissionType.Write
                }
            },
            {
                auth: true,
                children: null,
                componentPath: 'user-management/scenes/users',
                name: 'USERS',
                path: '/users/:id',
                permissions: {
                    users: PermissionType.Write
                }
            }
        ],
        componentPath: 'user-management',
        name: 'USER_MANAGEMENT',
        navigationElement: false,
        navigationLogo: null,
        path: '/user-management',
        permissions: {
            users: PermissionType.Write
        }
    },
    {
        auth: true,
        children: null,
        componentPath: 'no-bid-reasons',
        name: 'NO_BID_REASONS',
        navigationElement: false,
        path: '/no-bid-reasons/:id',
        permissions: {
            ad_no_bid_reasons: PermissionType.Read,
            ads: PermissionType.Read,
            adx_creatives: PermissionType.Read,
            dsp_ads: PermissionType.Read
        }
    }
]

export default createRoutePaths(routes)
