export async function addItem(key: string, value: string): Promise<boolean> {
    localStorage.setItem(key, value)

    return true
}

export async function removeItem(key: string): Promise<boolean> {
    localStorage.removeItem(key)

    return true
}

export async function getItem(key: string): Promise<string> {
    return localStorage.getItem(key)
}

export async function hasItem(key: string): Promise<boolean> {
    return localStorage.getItem(key) !== null
}
