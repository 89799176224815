import * as React from 'react'

import Translate from 'components/translate'
import ErrorBoundary from './components/error-boundary'

export type ReactComponent = React.FunctionComponent | React.ComponentClass
export type ComponentLoader = () => Promise<IReactElement>

interface IReactElement extends Object {
    default: ReactComponent
}

interface IProps extends React.Props<object> {
    load: ComponentLoader
}

export default class Bundle extends React.Component<IProps> {
    public shouldComponentUpdate(nextProps: IProps): boolean {
        return (
            nextProps.load !== this.props.load
        )
    }

    public render(): React.ReactNode {
        // tslint:disable-next-line: variable-name
        const LazyComponent = React.lazy(this.props.load)
        const layout = (
            <ErrorBoundary>
                <React.Suspense
                    fallback={(
                        <div className='row text-center'>
                            <div className='fetch-status col-sm-6 col-sm-offset-3'>
                                <Translate message='LOADING' tagName='h1' />
                            </div>
                        </div>
                    )}
                >
                    <LazyComponent />
                </React.Suspense>
            </ErrorBoundary>
        )

        return layout
    }
}
