import { TRANSLATION_PROVIDER } from './constants'
import { IProviderAction, IState } from './typings'

function reduceProvider(action: IProviderAction): IState {
    return {
        provider: action.provider
    }
}

function reduceDestroy(): IState {
    return {
        provider: null
    }
}

export default function reducer(
    state: IState = reduceDestroy(),
    action: IReduxAction
): IState {
    switch (action.type) {
        case TRANSLATION_PROVIDER:
            return reduceProvider(action as IProviderAction)
        default:
            return state
    }
}
