import { MODAL_SET } from './constants'
import { ModalAction, State } from './typings'

export default function modalReducer(
    _state: State,
    action: ModalAction | IReduxAction
): State {
    switch (action.type) {
        case MODAL_SET:
            return {
                cancelFn: (action as ModalAction).cancelFn,
                children: (action as ModalAction).children,
                name: (action as ModalAction).name,
                size: (action as ModalAction).size,
                successFn: (action as ModalAction).successFn
            }
    }

    return null
}
