import { matchPath } from 'react-router'

import { hasDaaPermission } from 'services/user'
import { IRoutePermissions } from 'typings/route-object'
import PermissionError from './permission-error'
import { isDevelopment, isStaging } from './env'

interface IMatchPathOptions extends Object {
    exact: boolean
    path: string
    strict: boolean
}

interface IRoutePathNode<T extends IRoute> extends Object {
    parent: T[]
    entity: T[]
    index: number
}

export interface IRoute extends Object {
    path: string
    children: IRoute[]
    permissions?: IRoutePermissions
}

export interface IRouteTree<T extends IRoute> extends Object {
    current: T
    parent: IRouteTree<T> | null
    children: Array<IRouteTree<T>> | null
}

export type RouteMap<T extends IRoute> = Map<string, IRouteTree<T>>

/**
 * cache default matching options
 */
const pathMatchOptions: IMatchPathOptions = {
    exact: true,
    path: null,
    strict: true
}

function transformIterateTree<T extends IRoute>(route: T, parentRoute: IRouteTree<T> = null): IRouteTree<T> {
    const treeBranch: IRouteTree<T> = {
        children: null,
        current: route,
        parent: parentRoute
    }

    if (route.children) {
        treeBranch.children = route.children.map((current: T) => transformIterateTree<T>(current, treeBranch))
    }

    return treeBranch
}

function addTreeBranchToMap<T extends IRoute>(treeBranch: IRouteTree<T>, map: RouteMap<T>): void {
    map.set(treeBranch.current.path, treeBranch)

    if (treeBranch.children) {
        for (const subRoute of treeBranch.children) {
            map.set(subRoute.current.path, subRoute)

            if (subRoute.children) {
                for (const subSubRoute of subRoute.children) {
                    addTreeBranchToMap<T>(subSubRoute, map)
                }
            }
        }
    }
}

function checkRoutePermissions(route: IRoute): boolean {
    if (!route.permissions) {
        return true
    }

    const resourceList = Object.keys(route.permissions)
    const length = resourceList.length
    let index = -1

    while (++index < length) {
        const resource = resourceList[index]
        const permissionType = route.permissions[resource]

        if (!hasDaaPermission(resource, permissionType)) {
            if (isDevelopment || isStaging) {
                console.warn(
                    `not permitted to access "${route.path}" because `
                    + (new PermissionError(resource, permissionType)).message
                )
            }

            return false
        }
    }

    return true
}

function buildRoutePath<T extends IRoute>(route: T, parents: Array<IRoutePathNode<T>>): string {
    let path = ''

    for (const parent of parents) {
        path += parent.entity[parent.index].path
    }

    return path + route.path
}

export function routeTree<T extends IRoute>(routeList: T[]): Array<IRouteTree<T>> {
    return routeList.map(
        // tslint:disable-next-line:no-unnecessary-callback-wrapper
        (route: T) => transformIterateTree<T>(route)
    )
}

export function routeMap<T extends IRoute>(routeList: T[]): RouteMap<T> {
    const tree = routeTree<T>(routeList)
    const map: RouteMap<T> = new Map()

    for (const entry of tree) {
        addTreeBranchToMap<T>(entry, map)
    }

    return map
}

/**
 * @description checks if the given path is in the routeList and if the user has access to the route
 */
export function findMatchingRoute<T extends IRoute>(path: string, routeList: T[]): T | null {
    for (const route of routeList) {
        pathMatchOptions.path = route.path

        if (matchPath(path, pathMatchOptions) && checkRoutePermissions(route)) {
            return route
        }

        if (route.children && route.children.length !== 0) {
            const childRoute = findMatchingRoute<T>(path, route.children as T[])

            if (childRoute !== null) {
                return childRoute
            }
        }
    }

    return null
}

/**
 * WARNING
 * this function doesn't check if the user has permission to the passed path
 */
export function routeExists<T extends IRoute>(path: string, routeList: T[]): boolean {
    for (const route of routeList) {
        pathMatchOptions.path = route.path

        if (
            matchPath(path, pathMatchOptions) ||
            (
                route.children &&
                route.children.length !== 0 &&
                routeExists(path, route.children as T[])
            )
        ) {
            return true
        }
    }

    return false
}

export function createRoutePaths<T extends IRoute>(routeList: T[]): T[] {
    const path: Array<IRoutePathNode<T>> = []
    let parentList: T[] = []
    let current: T[] = routeList
    let index = 0

    while (true) {
        if (index >= current.length) {
            if (path.length !== 0) {
                const node = path.pop()

                parentList = node.parent
                current = node.entity
                index = node.index + 1
                continue
            }

            break
        }

        parentList.push({
            ...current[index] as T,
            children: null,
            path: buildRoutePath(current[index], path)
        })

        if (current[index].children instanceof Array) {
            const node = parentList[parentList.length - 1]

            path.push({ entity: current, parent: parentList, index })

            parentList = node.children = [] as T[]
            current = current[index].children as T[]
            index = 0
            continue
        }

        index++
    }

    return parentList
}
