const base64Alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='

export function encode(input: string): string {
    return btoa(
        encodeURIComponent(input)
            .replace(/%([0-9A-F]{2})/g, (_fullMatch, match) => String.fromCharCode(parseInt('0x' + match, 16)))
    )
}

export function decode(input: string): string {
    return decodeURIComponent(
        atob(input)
            .split('')
            .map(
                (char) => {
                    return '%' + (
                        '00' +
                        (
                            char.charCodeAt(0)
                                .toString(16)
                        )
                    ).slice(-2)
                }
            )
            .join('')
    )
}

export function encodeBin(input: Uint8Array): string | null {
    const length = input.byteLength
    const rest = length % 3
    let result = ''

    for (let i = 0; i < length;) {
        const a = input[i++]
        const b = input[i++]
        const c = input[i++]

        if (a > 255 || b > 255 || c > 255) {
            return null // invalid byte size (0, 255)
        }

        // tslint:disable: no-bitwise
        const bitmap = (a << 16) | (b << 8) | c
        result += base64Alphabet.charAt(bitmap >> 18 & 63) + base64Alphabet.charAt(bitmap >> 12 & 63)
            + base64Alphabet.charAt(bitmap >> 6 & 63) + base64Alphabet.charAt(bitmap & 63)
        // tslint:enable: no-bitwise
    }

    return rest !== 0 ? result.slice(0, rest - 3) + '==='.substring(rest) : result
}
