import {SELECTED_TARGETING_OPTIONS_COUNT, TOGGLE_PARENT_BUTTONS} from './constants'

export interface IAction extends IReduxAction {
    count?: number
    isHidden?: boolean
}

export interface IState extends Object {
    count: number
    isHidden: boolean
}

export default function targetingOptionsReducer(
    state: IState,
    action: IAction
): IState {
    if (!state) {
        return {
            count: 0,
            isHidden: false
        }
    }

    switch (action.type) {
        case TOGGLE_PARENT_BUTTONS:
            return {
                count: state.count,
                isHidden: action.isHidden
            }
        case SELECTED_TARGETING_OPTIONS_COUNT:
            return {
                count: action.count,
                isHidden: state.isHidden
            }
    }

    return state
}
