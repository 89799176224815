import { PureObjectKeys } from '@ad4mat/javascript-utilities/dist/typings/object'
import * as qs from 'qs'
import axios, { AxiosResponse } from 'axios'

import { getVersion } from 'lib/version'
import {
    ICreatePermission,
    ICreateRole,
    ICreateUser,
    ICredentials,
    IoAuthPermission,
    IoAuthRole,
    IoAuthToken,
    IoAuthUser,
    IPasswordResetCredentials,
    IPasswordResetRequestCredentials,
    IRefreshCredentials,
    IRoleData,
    IToken,
    ITokenCredentials,
    ITokenInfo,
    ITokenInfoResponse,
    IUser,
    IUserInfoResponse,
    PermissionMap,
    IPaginatedResponse,
    SortOrder,
    RawPaginatedResponse,
    IPaginatedUserOptions,
    IPaginatedRoleOptions
} from 'services/oauth/typings'
import { encode } from '../../lib/base64'
import { BASE_URL, PermissionType } from './constants'

const AXIOS = axios.create({
    // remove trailing slash
    baseURL: BASE_URL.replace(/\/$/, ''),
    headers: {
        'X-Requested-With': 'ONE-UI ' + getVersion()
    },
    paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: 'brackets' }),
    timeout: 10000
})

function mapPermissionType(type: string): PermissionType {
    switch (type) {
        case 'read':
            return PermissionType.Read
        case 'write':
            return PermissionType.Write
        case 'delete':
            return PermissionType.Delete
        default:
            return PermissionType.Unkown
    }
}

function checkResponseStatus(response: AxiosResponse<any>): boolean {
    return response.status === 200
}

function getResponse<T = any>(response: AxiosResponse<T>): T {
    return response.data
}

function parseToken(response: AxiosResponse<IoAuthToken>): IToken {
    const now = Date.now()

    return {
        expires: now + (response.data.expires_in * 1000),
        fetched: now,
        refreshToken: response.data.refresh_token,
        token: response.data.access_token,
        type: response.data.token_type
    }
}

function parseUser(response: AxiosResponse<any>): IUser {
    const data = (response.data[0] || response.data) as IUserInfoResponse

    return {
        firstname: data.firstname,
        id: data.id,
        lastname: data.lastname,
        roles: data.roles,
        username: data.username
    }
}

function parsePermissions(roleData: IRoleData, roles: string[]): PermissionMap {
    const delimiter = '.'
    const permissionMap = new Map<string, PermissionType[]>()

    for (const role of roles) {
        if (roleData[role] instanceof Array) {
            for (const permission of roleData[role]) {
                const delimiterIndex = permission.lastIndexOf(delimiter)

                if (delimiterIndex === -1) {
                    continue
                }

                const permissionKey = permission.slice(0, delimiterIndex)
                const type = permission.slice(delimiterIndex + 1)

                if (permissionMap.has(permissionKey)) {
                    permissionMap
                        .get(permissionKey)
                        .push(mapPermissionType(type))
                } else {
                    permissionMap.set(permissionKey, [mapPermissionType(type)])
                }
            }
        }
    }

    return permissionMap
}

function parseTokenInfo(response: AxiosResponse<ITokenInfoResponse>): ITokenInfo {
    const roles = Object.keys(response.data.principal.roles)

    return {
        permissions: parsePermissions(response.data.principal.roles, roles),
        roles
    }
}

export function validateToken(token: IToken): boolean {
    const now = Date.now()

    return token.expires > now && token.fetched < now
}

export async function getToken(credentials: ICredentials): Promise<IToken> {
    return parseToken(
        await AXIOS.post(
            '/oauth2/token',
            qs.stringify({
                ...credentials,
                grant_type: 'password'
            }),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        )
    )
}

export async function refreshToken(credentials: IRefreshCredentials): Promise<IToken> {
    return parseToken(
        await AXIOS.post(
            '/oauth2/token',
            qs.stringify({
                ...credentials,
                grant_type: 'refresh_token'
            }),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        )
    )
}

export async function revokeToken(credentials: ITokenCredentials): Promise<boolean> {
    return checkResponseStatus(
        await AXIOS.post(
            '/oauth2/revoke',
            qs.stringify(credentials),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        )
    )
}

export async function tokenInfo(credentials: ITokenCredentials): Promise<ITokenInfo> {
    return parseTokenInfo(
        await AXIOS.get(
            '/v1/tokeninfo',
            {
                headers: {
                    Authorization: 'Basic ' + encode(credentials.client_id + ':' + credentials.client_secret)
                },
                params: {
                    access_token: credentials.token
                }
            }
        )
    )
}

export async function tokenUser(token: IToken): Promise<IUser> {
    return parseUser(
        await AXIOS.get(
            '/oauth2/resourceOwner',
            {
                headers: {
                    'Authorization': token.type + ' ' + token.token,
                    'Content-Type': 'application/json'
                }
            }
        )
    )
}

export async function passwordResetRequest(credentials: IPasswordResetRequestCredentials): Promise<boolean> {
    return checkResponseStatus(
        await AXIOS.post(
            '/oauth2/resetPassword',
            qs.stringify(credentials),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        )
    )
}

export async function passwordReset(credentials: IPasswordResetCredentials): Promise<boolean> {
    return checkResponseStatus(
        await AXIOS.post(
            '/oauth2/resetPassword',
            qs.stringify(credentials),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        )
    )
}

export async function createUser(token: IToken, password: string, data: ICreateUser): Promise<IoAuthUser> {
    return getResponse(
        await AXIOS.put(
            '/admin/resourceOwner',
            data,
            {
                headers: {
                    Authorization: token.type + ' ' + token.token
                },
                params: {
                    password: encodeURIComponent(password)
                }
            }
        )
    )
}

export async function createRole(token: IToken, data: ICreateRole): Promise<IoAuthRole> {
    return getResponse(
        await AXIOS.put(
            '/admin/role',
            data,
            {
                headers: {
                    Authorization: token.type + ' ' + token.token
                }
            }
        )
    )
}

export async function createPermission(token: IToken, data: ICreatePermission): Promise<IoAuthPermission> {
    return getResponse(
        await AXIOS.put(
            '/admin/accessPermission',
            data,
            {
                headers: {
                    Authorization: token.type + ' ' + token.token
                }
            }
        )
    )
}

export async function updateUser(token: IToken, userId: number, data: ICreateUser): Promise<IoAuthUser> {
    return getResponse(
        await AXIOS.post(
            '/admin/resourceOwner/' + userId,
            data,
            {
                headers: {
                    'Authorization': token.type + ' ' + token.token,
                    'Content-Type': 'application/json'
                }
            }
        )
    )
}

export async function updateRole(token: IToken, roleId: number, data: ICreateRole): Promise<IoAuthRole> {
    return getResponse(
        await AXIOS.post(
            '/admin/role/' + roleId,
            data,
            {
                headers: {
                    'Authorization': token.type + ' ' + token.token,
                    'Content-Type': 'application/json'
                }
            }
        )
    )
}

export async function updatePermission(
    token: IToken,
    permissionId: number,
    data: ICreatePermission
): Promise<IoAuthPermission> {
    return getResponse(
        await AXIOS.post(
            '/admin/accessPermission/' + permissionId,
            data,
            {
                headers: {
                    'Authorization': token.type + ' ' + token.token,
                    'Content-Type': 'application/json'
                }
            }
        )
    )
}

export async function deleteUser(token: IToken, userId: number): Promise<boolean> {
    return checkResponseStatus(
        await AXIOS.delete(
            '/admin/resourceOwner/' + userId,
            {
                headers: {
                    Authorization: token.type + ' ' + token.token
                }
            }
        )
    )
}

export async function deleteRole(token: IToken, roleId: number): Promise<boolean> {
    return checkResponseStatus(
        await AXIOS.delete(
            '/admin/role/' + roleId,
            {
                headers: {
                    Authorization: token.type + ' ' + token.token
                }
            }
        )
    )
}

export async function deletePermission(token: IToken, permissionId: number): Promise<boolean> {
    return checkResponseStatus(
        await AXIOS.delete(
            '/admin/accessPermission/' + permissionId,
            {
                headers: {
                    Authorization: token.type + ' ' + token.token
                }
            }
        )
    )
}

export async function fetchPaginatedUsers(
    token: IToken,
    options: IPaginatedUserOptions
): Promise<IPaginatedResponse<IoAuthUser[]>> {
    const params: Record<string, string | number> = {
        page: options.page,
        size: options.pageSize
    }

    if (options.sort) {
        params.sort = options.sort.attribute + ',' + options.sort.order.toLowerCase()
    }

    if (options.search) {
        params[options.search.attribute] = options.search.value
    }

    const response = await AXIOS.get<IoAuthUser[], RawPaginatedResponse<IoAuthUser[]>>(
        '/admin/resourceOwner',
        {
            headers: {
                Authorization: token.type + ' ' + token.token
            },
            params
        }
    )

    return {
        data: response.data.filter((entity) => entity !== null && typeof entity === 'object'),
        page: options.page,
        pageSize: options.pageSize,
        totalEntries: (typeof response.headers['pagination-item-count'] === 'string')
            ? +response.headers['pagination-item-count']
            : 0,
        totalPages: (typeof response.headers['pagination-total-pages'] === 'string')
            ? +response.headers['pagination-total-pages']
            : 0
    }
}

export async function fetchUser(token: IToken, userId: number): Promise<IoAuthUser> {
    return getResponse(
        await AXIOS.get(
            '/admin/resourceOwner/' + userId,
            {
                headers: {
                    Authorization: token.type + ' ' + token.token
                }
            }
        )
    )
}

export async function fetchRoles(
    token: IToken,
    sortAttribute?: PureObjectKeys<IoAuthRole>,
    sortOrder?: SortOrder
): Promise<IoAuthRole[]> {
    return getResponse(
        await AXIOS.get(
            '/admin/role',
            {
                headers: {
                    Authorization: token.type + ' ' + token.token
                },
                params: (sortAttribute && sortOrder)
                    ? { sort: sortAttribute + ',' + sortOrder.toLowerCase() }
                    : undefined
            }
        )
    )
}

export async function fetchPaginatedRoles(
    token: IToken,
    options: IPaginatedRoleOptions
): Promise<IPaginatedResponse<IoAuthRole[]>> {
    const params: Record<string, string | number> = {
        page: options.page,
        size: options.pageSize
    }

    if (options.sort) {
        params.sort = options.sort.attribute + ',' + options.sort.order.toLowerCase()
    }

    if (options.search) {
        params[options.search.attribute] = options.search.value
    }

    const response = await AXIOS.get<IoAuthRole[], RawPaginatedResponse<IoAuthRole[]>>(
        '/admin/role',
        {
            headers: {
                Authorization: token.type + ' ' + token.token
            },
            params
        }
    )

    return {
        data: response.data.filter((entity) => entity !== null && typeof entity === 'object'),
        page: options.page,
        pageSize: options.pageSize,
        totalEntries: (typeof response.headers['pagination-item-count'] === 'string')
            ? +response.headers['pagination-item-count']
            : 0,
        totalPages: (typeof response.headers['pagination-total-pages'] === 'string')
            ? +response.headers['pagination-total-pages']
            : 0
    }
}

export async function fetchRole(token: IToken, roleId: number): Promise<IoAuthRole> {
    return getResponse(
        await AXIOS.get(
            '/admin/role/' + roleId,
            {
                headers: {
                    Authorization: token.type + ' ' + token.token
                }
            }
        )
    )
}

export async function fetchPermissions(token: IToken): Promise<IoAuthPermission[]> {
    return getResponse(
        await AXIOS.get(
            '/admin/accessPermission',
            {
                headers: {
                    Authorization: token.type + ' ' + token.token
                }
            }
        )
    )
}

export async function fetchPermission(token: IToken, permissionId: number): Promise<IoAuthPermission> {
    return getResponse(
        await AXIOS.get(
            '/admin/accessPermission/' + permissionId,
            {
                headers: {
                    Authorization: token.type + ' ' + token.token
                }
            }
        )
    )
}
