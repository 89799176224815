import { FORM_STATE_CHANGE } from './constants'
import { FormStateAction, IFormState } from './typings/redux-state'

function defaultState(): IFormState {
    return {
        allowDelete: false,
        allowSave: false,
        disableButtons: false,
        isCreateView: false
    }
}

export default function formStateReducer(
    state: IFormState = defaultState(),
    action: FormStateAction
): IFormState {
    switch (action.type) {
        case FORM_STATE_CHANGE:
            return {
                allowDelete: (typeof action.allowDelete === 'boolean') ? action.allowDelete : state.allowDelete,
                allowSave: (typeof action.allowSave === 'boolean') ? action.allowSave : state.allowSave,
                disableButtons: (typeof action.disableButtons === 'boolean') ? action.disableButtons : state.disableButtons,
                isCreateView: (typeof action.isCreateView === 'boolean') ? action.isCreateView : state.isCreateView
            }
    }

    return state
}
