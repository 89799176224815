import { applyMiddleware, combineReducers, createStore, Middleware } from 'redux'
import reduxLogger from 'redux-logger'
import reduxThunk from 'redux-thunk'

import componentReducer from 'components/reducer'
import dataReducer from 'data/reducer'
import { isDevelopment } from 'lib/env'
import sceneReducer from 'scenes/reducer'
import serviceReducer from 'services/reducer'
import userReducer from 'services/user-reducer'
import { IState } from 'services/user-session/typings'
import IDataState from 'typings/data-state'

const dataMiddleware: Middleware[] = [
    reduxThunk
]

const userMiddleware: Middleware[] = [
    reduxThunk
]

if (isDevelopment) {
    dataMiddleware.push(reduxLogger)
    userMiddleware.push(reduxLogger)
}

export const data = createStore<IDataState, IReduxAction, object, object>(
    combineReducers<any, IReduxAction>({
        components: componentReducer(),
        data: dataReducer(),
        scenes: sceneReducer(),
        services: serviceReducer()
    }),
    applyMiddleware.apply(applyMiddleware, dataMiddleware)
)

export const user = createStore<IState, IReduxAction, object, object>(
    userReducer(),
    applyMiddleware.apply(applyMiddleware, userMiddleware)
)
