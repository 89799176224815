export enum ButtonMethodType {
    SUBMIT = 'submit',
    RESET = 'reset',
    BUTTON = 'button'
}

export enum ButtonSize {
    SMALL = 'uk-button-small',
    LARGE = 'uk-button-large'
}

export enum ButtonClassType {
    PRIMARY = 'uk-button-primary',
    SECONDARY = 'uk-button-secondary',
    DANGER = 'uk-button-danger',
    DEFAULT = 'uk-button-default',
    BLUE = 'uk-button-blue',
    INHERIT = 'as-button-inherit'
}
