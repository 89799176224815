import { combineReducers } from 'redux'

import formStateReducer from './form/reducer'
import modalReducer from './modal/reducer'
import languageSwitchReducer from './navigation-bar/components/language-switch/reducer'
import targetingOptionReducer from './targeting-options/reducer'

export default function reducer() {
    return combineReducers({
        formState: formStateReducer,
        languageSwitch: languageSwitchReducer,
        modal: modalReducer,
        targetingOptions: targetingOptionReducer
    })
}
