import {
    AUTHORIZATION_SERVER_ADMIN_CLIENT_CREDENTIALS,
    AUTHORIZATION_SERVER_CLIENT_CREDENTIALS,
    CLIENT_CREDENTIALS,
    STORAGE_ACCESS_CREDENTIALS
} from '../oauth/constants'
import { TokenType } from '../user-session/constants'

export const enum SessionEventTypes {
    PING,
    PONG,
    LOGOUT,
    DAA_TOKEN,
    USER_TOKEN,
    ADMIN_TOKEN,
    USER_DATA_UPDATE,
    REFRESH_LOCK_RELEASE,
    PERMISSION_UPDATE,
    LOGIN,
    SAA_TOKEN,
    ROLE_UPDATE
}

export const CREDENTIALS = new Map([
    [TokenType.AdminAuth, AUTHORIZATION_SERVER_ADMIN_CLIENT_CREDENTIALS],
    [TokenType.UserAuth, AUTHORIZATION_SERVER_CLIENT_CREDENTIALS],
    [TokenType.DataAccessApi, CLIENT_CREDENTIALS],
    [TokenType.StorageAccessApi, STORAGE_ACCESS_CREDENTIALS]
])

export const SESSION_KEY = '_AO_SK'
export const INSTANCE_LIST_KEY = '_AO_IL'
export const REFRESH_LOCK_KEY = '_AO_RL'
export const REFRESH_PERCENTAGE = 90
