import { Workbox as WBType } from 'workbox-window'

import { NotificationType, notifyUser, userConfirm } from 'lib/notification-api'

function confirmUpgrade(): Promise<boolean> {
    return userConfirm('UPDATE_AVAILABLE', 'UPDATE_NOTICE', 'UPDATE_NOW', 'UPDATE_LATER')
}

function sendUpdate(sw: WBType): () => Promise<void> {
    // tslint:disable-next-line: space-before-function-paren
    return async () => {
        if (await confirmUpgrade()) {
            (sw.messageSW as any)({ type: 'SKIP_WAITING' })
        }
    }
}

function serviceWorkerChange(): void {
    window.location.reload()
}

function handleRegistrationError(err: Error): void {
    notifyUser('ERR_SERVICE_WORKER', 'SERVICE_WORKER_REGISTRATION_FAILED', NotificationType.WARNING)
    console.error(err)
}

export default async function registerServiceWorker(): Promise<void> {
    const { Workbox } = await import('workbox-window')
    const sw = new Workbox('/service-worker.js')

    sw.addEventListener('controlling', serviceWorkerChange)
    sw.addEventListener('waiting', sendUpdate(sw))
    sw.addEventListener('activated', serviceWorkerChange)

    try {
        await sw.register()
    } catch (err) {
        handleRegistrationError(err as Error)
    }
}
