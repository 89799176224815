import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, RouteComponentProps, withRouter } from 'react-router'

import Bundle, { ComponentLoader } from 'components/bundle/index'
import NotFound from 'components/not-found/index'
import { findMatchingRoute } from 'lib/route-util'
import routeList from 'lib/routes'
import IDataState from 'typings/data-state'
import { IRouteElement } from 'typings/route-object'

interface IStateProps extends Object {
    loggedIn: boolean
}

type Props = IStateProps & RouteComponentProps<never>

interface IState extends Object {
    loggedIn: boolean
    redirectTo: string
    route: IRouteElement
    routeLoader: ComponentLoader
    routePath: string
}

class ActiveRoute extends React.Component<Props, IState> {
    public constructor(props: Props, context: object) {
        super(props, context)

        this.state = {
            loggedIn: false,
            redirectTo: null,
            route: null,
            routeLoader: null,
            routePath: null
        }
    }

    public static getDerivedStateFromProps(nextProps: Props, prevState: IState): IState | null {
        if (
            nextProps.location.pathname !== prevState.routePath ||
            nextProps.loggedIn !== prevState.loggedIn
        ) {
            const routePath = nextProps.location.pathname
            const route = findMatchingRoute(routePath, routeList)
            const hasRoute = route !== null

            if (!hasRoute && nextProps.loggedIn) {
                return {
                    loggedIn: nextProps.loggedIn,
                    redirectTo: '/profile',
                    route: null,
                    routeLoader: null,
                    routePath
                }
            }

            if (hasRoute && route.auth && !nextProps.loggedIn) {
                return {
                    loggedIn: nextProps.loggedIn,
                    redirectTo: null,
                    route: null,
                    routeLoader: null,
                    routePath
                }
            }

            if (hasRoute && !route.componentPath) {
                return {
                    loggedIn: nextProps.loggedIn,
                    redirectTo: '/dashboard',
                    route: null,
                    routeLoader: null,
                    routePath
                }
            }

            return {
                loggedIn: nextProps.loggedIn,
                redirectTo: null,
                route,
                routeLoader: ActiveRoute.getComponentLoader(route),
                routePath
            }
        }

        return null
    }

    private static getComponentLoader(route: IRouteElement): ComponentLoader | null {
        if (!route) {
            return null
        }

        return () => import('scenes/' + route.componentPath + '/index.tsx')
    }

    public shouldComponentUpdate(_nextProps: Props, nextState: IState): boolean {
        return (
            nextState.route !== this.state.route ||
            nextState.routePath !== this.state.routePath
        )
    }

    public render(): React.ReactElement<HTMLElement> {
        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} push={false} />
        }

        if (!this.state.route) {
            return <NotFound />
        }

        return (
            <Route exact={true} path={this.state.route.path} push={true}>
                <Bundle load={this.state.routeLoader} />
            </Route>
        )
    }
}

function mapStateToProps(state: IDataState) {
    return {
        loggedIn: state.scenes.login.loggedIn
    }
}

export default withRouter(connect(mapStateToProps)(ActiveRoute))
