import { PermissionType } from 'services/oauth/constants'
import { hasDaaPermission } from 'services/user'
import { data } from './store'

data.subscribe(themeSwitcher)

const enum Theme {
    AWIN = 'theme-awin-ads',
    advancedStore = 'theme-advanced-store'
}

function themeSwitcher(): void {
    const state = data.getState()

    if (!state.scenes.login.loggedIn) {
        return
    }

    if (hasDaaPermission('theme', PermissionType.Unkown)) {
        document.body.classList.remove(Theme.advancedStore)
        document.body.classList.add(Theme.AWIN)
    } else {
        document.body.classList.remove(Theme.AWIN)
        document.body.classList.add(Theme.advancedStore)
    }
}
