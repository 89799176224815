import { AxiosError } from 'axios'

import { user } from 'lib/store'
import { cloudFunctionPost } from 'services/google-cloud/api'
import { CloudFunction } from 'services/google-cloud/constants'
import { IToken } from 'services/oauth/typings'
import { getToken } from 'services/user-session'
import { TokenType } from 'services/user-session/constants'
import { IFireFoxError } from 'typings/browser'
import { isBlink, isFirefox } from './browser'
import { isProduction, isStaging } from './env'
import { getFullVersion } from './version'

interface IErrorContext extends Object {
    context: {
        httpRequest: {
            method: string
            referrer: string
            url: string
            userAgent: string
        }
        reportLocation: {
            filePath: string
            functionName: string
            lineNumber: number
        }
        user: string
    }
    eventTime: string
    message: string
    serviceContext: {
        resourceType: string
        service: string
        version: string
    }
}

const shouldSubmitReport = isProduction || isStaging ||
    /(?:[a-z]+\.)?ad4mat\.one/.test(window.location.hostname)
const errorLog = console.error
const version = getFullVersion()
    .trim()

function createReport(rawMessage: string, error?: Error): IErrorContext {
    const userState = user.getState()
    let message = rawMessage
    let reportLocation = {
        filePath: './src/lib/error-report.js',
        functionName: 'report',
        lineNumber: 1
    }

    if (error && error instanceof Error) {
        if (typeof (error as AxiosError).config === 'object') {
            const reqConfig = (error as AxiosError).config

            message += '\n' + reqConfig.method.toUpperCase() + ' ' + reqConfig.url
            reportLocation.filePath = './node_modules/axios/index.js'
            reportLocation.functionName = 'axios_request'
        } else {
            message += '\n' + (error.stack || '')

            if (isBlink) {
                reportLocation = null
            } else if (isFirefox) {
                reportLocation.filePath = (error as IFireFoxError).fileName
                reportLocation.lineNumber = (error as IFireFoxError).lineNumber
            }
        }
    }

    return {
        context: {
            httpRequest: {
                method: 'GET',
                referrer: window.location.origin,
                url: window.location.href,
                userAgent: navigator.userAgent
            },
            reportLocation,
            user: userState.user.data.id + ''
        },
        eventTime: (new Date()).toISOString(),
        message,
        serviceContext: {
            resourceType: 'global',
            service: 'ad4mat.one',
            version
        }
    }
}

async function sendReport(data: object): Promise<boolean> {
    const token: IToken = getToken(TokenType.DataAccessApi)

    if (!token) {
        return false
    }

    return (
        await cloudFunctionPost(
            CloudFunction.reportError,
            data,
            { authToken: token.type + ' ' + token.token }
        )
    ).status === 200
}

function consoleHandler(...args: any[]) {
    errorLog.apply(console, args)

    const length = args.length
    let index = -1
    let error: Error
    let message: string

    while (++index < length) {
        const arg = args[index]

        if (arg instanceof Error) {
            error = arg
        } else if (typeof arg === 'string') {
            message = arg
        }

        if (error && message) {
            break
        }
    }

    if (error && !message) {
        message = error + ''
    }

    sendReport(
        createReport(
            message,
            error
        )
    )
}

function eventHandler(message: string, _source: string, _lineno: number, _colno: number, error: Error): void {
    sendReport(
        createReport(
            message,
            error
        )
    )
}

export default function errorReport(): void {
    if (!shouldSubmitReport || window.onerror === eventHandler) {
        return
    }

    window.onerror = eventHandler
    console.error = consoleHandler
}
