const prefix = 'USER_SESSION_'

export const SET_TOKEN = prefix + 'SET_TOKEN'
export const REMOVE_TOKEN = prefix + 'REMOVE_TOKEN'
export const SET_PREFERENCE = prefix + 'SET_PREFERENCE'
export const DESTROY_STATE = prefix + 'DESTROY_STATE'
export const SET_USER = prefix + 'SET_USER'
export const SET_PERMISSIONS = prefix + 'SET_PERMISSIONS'
export const REMOVE_PREFERENCE = prefix + 'REMOVE_PREFERENCE'
export const STATE_STORAGE_KEY = '_AO_US'
export const STATE_USER_PREFERNCES = '_AO_PR'

export const enum TokenType {
    DataAccessApi,
    UserAuth,
    AdminAuth,
    StorageAccessApi
}

export const enum StateLoadType {
    None,
    Token,
    UserPreference
}
