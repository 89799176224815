import { isProduction, isStaging } from 'lib/env'

export const BASE_URL = (isProduction)
    ? 'https://oauth2.ad4mat.net/'
    : (isStaging)
        ? 'https://staging-oauth2.ad4mat.net/'
        : 'https://dev-oauth2.ad4mat.net/'

export const RESOURCE_SERVER_CREDENTIALS = Object.freeze({
    client_id: 'daa',
    client_secret: 'yiMfY59Mx1GhpkWN2CDy'
})

export const CLIENT_CREDENTIALS = Object.freeze({
    client_id: 'km',
    client_secret: 'B8l4jERbUutYXrpuzIlo'
})

export const AUTHORIZATION_SERVER_CLIENT_CREDENTIALS = Object.freeze({
    client_id: 'authorization-server-js-client',
    client_secret: 'hs2hfEjcy8T9L6rhN4m2'
})

export const AUTHORIZATION_SERVER_ADMIN_CLIENT_CREDENTIALS = Object.freeze({
    client_id: 'authorization-server-admin-js-client',
    client_secret: 'br4HMCzjtJw4GxJgjxAc'
})

export const STORAGE_ACCESS_CREDENTIALS = Object.freeze({
    client_id: 'one-intern',
    client_secret: 'y43Ja3KEXcgjsngWUQBjb7sw'
})

export const enum PermissionType {
    Unkown = -1,
    Read = 0,
    Write = 1,
    Delete = 2
}
