type EntrySet<T = any> = Set<T>
export type BasicTypeArray = Array<string | number | boolean>

export default function arrayUniqueValue<T extends BasicTypeArray>(arr: T): T {
    const result = [] as T
    const map: EntrySet<T[number]> = new Set()
    const arrLength = arr.length
    let index = -1

    while (++index < arrLength) {
        const entry = arr[index]

        if (map.has(entry)) {
            continue
        }

        map.add(entry)
        result.push(entry)
    }

    return result
}
