import { IResponseError } from 'services/daa/typings'

export default class DAAError extends Error {
    public errorList: IResponseError[]
    public status: number

    public constructor(errorList: IResponseError[]) {
        super()

        this.errorList = errorList
        this.name = this.getErrorName()
        this.message = this.getErrorMessage()
        this.status = this.getErrorStatus()
    }

    private getErrorName(): string {
        return this.errorList[0].title.replace(/\s+/, '_')
            .toUpperCase()
    }

    private getErrorMessage(): string {
        return this.errorList[0].detail
    }

    private getErrorStatus(): number {
        return this.errorList[0].status
    }
}
