import { combineReducers, Reducer } from 'redux'

import LoadingReducer from './loading/reducer'
import TranslationReducer from './translations/reducer'
import IState from './typings/redux-state'

export default function reducer(): Reducer<IState> {
    return combineReducers({
        loading: LoadingReducer,
        translations: TranslationReducer
    })
}
