import { ad4mat } from 'ad4mat-daa-model/daa_model'

import { errorMessage } from 'lib/notification-api'
import { PermissionType } from 'services/oauth/constants'
import { ILanguageTranslation, ITranslations, Language } from 'services/storage-access/typings'
import daaFetchInitiator from '../../lib/daa-fetch'
import languageShortISO from '../../lib/lang-short-iso'
import { hasSaaPermission } from '../user'
import { getToken } from '../user-session'
import { TokenType } from '../user-session/constants'
import {
    createLanguage as createLanguageRequest,
    fetchTranslations as fetchTranslationsRequest,
    loadUserConfig as loadUserConfigRequest,
    removeAsset,
    removeLanguage as removeLanguageRequest,
    saveUserConfig as saveUserConfigRequest,
    updateTranslations as updateTranslationsRequest
} from './api'
import { STORAGE_URL } from './constants'

export async function createLanguage(
    lang: Language,
    translations?: ITranslations
): Promise<ILanguageTranslation[]> {
    return (
        await createLanguageRequest(
            getToken(TokenType.StorageAccessApi),
            languageShortISO(lang),
            translations
        )
    ).data
}

export async function fetchTranslations(
    lang: Language
): Promise<ILanguageTranslation[]> {
    return (await fetchTranslationsRequest(languageShortISO(lang))).data
}

export async function removeLanguage(
    lang: Language
): Promise<boolean> {
    return (
        await removeLanguageRequest(
            getToken(TokenType.StorageAccessApi),
            languageShortISO(lang)
        )
    ).status === 200
}

export async function updateTranslations(
    lang: Language,
    translations: ITranslations
): Promise<ILanguageTranslation[]> {
    return (
        await updateTranslationsRequest(
            getToken(TokenType.StorageAccessApi),
            languageShortISO(lang),
            translations
        )
    ).data
}

export async function deleteAsset(
    assetName: string
): Promise<void> {
    if (
        typeof assetName !== 'string'
        || assetName.trim().length === 0
        || !hasSaaPermission('assets', PermissionType.Delete)
    ) {
        return
    }

    const response = await daaFetchInitiator<ad4mat.API.GET.Ad.Data[][]>([{
        query: `image_url=='${STORAGE_URL + assetName}'`,
        resource: 'ads'
    }])

    if (response[0].data.length === 0) {
        removeAsset(
            getToken(TokenType.StorageAccessApi),
            assetName
        )
            .catch(errorMessage)
    }
}

export async function loadUserConfig(): Promise<object> {
    return (
        await loadUserConfigRequest(
            getToken(TokenType.StorageAccessApi)
        )
    ).data
}

export async function saveUserConfig(
    userConfig: object
): Promise<boolean> {
    return (
        await saveUserConfigRequest(
            getToken(TokenType.StorageAccessApi),
            userConfig
        )
    ).status === 200
}
