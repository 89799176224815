import { INSTANCE_LIST_KEY } from './constants'

export function removeFromInstanceList(instanceId: string): void {
    const instanceList = getInstanceList()
    const index = instanceList.indexOf(instanceId)

    if (index !== -1) {
        instanceList.splice(index, 1)
        setInstanceList(instanceList)
    }
}

export function addToInstanceList(instanceId: string): void {
    const instanceList = getInstanceList()

    if (instanceList.indexOf(instanceId) === -1) {
        instanceList.push(instanceId)
        setInstanceList(instanceList)
    }
}

export function setInstanceList(instanceList: string[]): void {
    try {
        if (instanceList.length > 0) {
            localStorage.setItem(INSTANCE_LIST_KEY, instanceList.join(','))
        } else {
            localStorage.removeItem(INSTANCE_LIST_KEY)
        }
    } catch (err) {
        // insertion failed
    }
}

export function getInstanceList(): string[] {
    try {
        const instanceList = localStorage.getItem(INSTANCE_LIST_KEY)

        return (instanceList !== null) ? instanceList.split(',') : []
    } catch (err) {
        return []
    }
}
