export function stripConditionals(query: string): string {
    if (!query) {
        return query
    }

    let result = query

    if (result.startsWith(';') || result.startsWith(',')) {
        result = result.slice(1)
    }

    if (result.endsWith(';') || result.endsWith(',')) {
        result = result.slice(0, -1)
    }

    return result
}
