import * as React from 'react'

import Translate from 'components/translate'

interface IState extends Object {
    hasError: boolean
}

export default class ErrorBoundary extends React.Component<object, IState> {
    public constructor(props: object, context: unknown) {
        super(props, context)

        this.state = {
            hasError: false
        }
    }

    public static getDerivedStateFromError(error: Error): IState {
        console.error(error)

        return {
            hasError: true
        }
    }

    public render(): React.ReactNode {
        if (this.state.hasError) {
            return (
                <div>
                    <div className='error'>
                        <Translate message='ROOT_ERROR_BOUNDARY' tagName='h1' />
                    </div>
                </div>
            )
        }

        return this.props.children
    }
}
