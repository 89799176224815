import axios, { AxiosResponse } from 'axios'

import { getVersion } from 'lib/version'
import { IToken } from 'services/oauth/typings'
import { Language, LanguageResponse } from 'services/storage-access/typings'
import { BASE_URL } from './constants'

const AXIOS = axios.create({
    baseURL: BASE_URL.replace(/\/$/, ''),
    headers: {
        'X-Requested-With': 'ONE-UI ' + getVersion()
    }
})

const AXIOS_USER_CONFIG = axios.create({
    baseURL: BASE_URL.replace(/\/[0-9]+\.[0-9]+\/?$/, ''),
    headers: {
        'X-Requested-With': 'ONE-UI ' + getVersion()
    }
})

export function getTokenHeader(token: IToken): object {
    return {
        Authorization: token.type + ' ' + token.token
    }
}

export async function fetchTranslations(
    lang: Language
): Promise<AxiosResponse<LanguageResponse>> {
    return AXIOS.get('/languages/' + lang)
}

export async function updateTranslations(
    token: IToken,
    lang: Language,
    translations: object
): Promise<AxiosResponse<LanguageResponse>> {
    const data = {
        translations,
        type: lang
    }

    return AXIOS.patch('/languages/' + lang, data, {
        headers: {
            ...getTokenHeader(token),
            'Content-Type': 'application/json'
        }
    })
}

export async function createLanguage(
    token: IToken,
    lang: Language,
    translations: object = {}
): Promise<AxiosResponse<LanguageResponse>> {
    const data = {
        translations,
        type: lang
    }

    return AXIOS.post('/languages', data, {
        headers: getTokenHeader(token)
    })
}

export async function removeLanguage(
    token: IToken,
    lang: Language
): Promise<AxiosResponse<any>> {
    return AXIOS.delete('/languages/' + lang, {
        headers: getTokenHeader(token)
    })
}

export async function removeAsset(
    token: IToken,
    assetName: string
): Promise<AxiosResponse<any>> {
    return AXIOS.delete('/assets/' + assetName, {
        headers: getTokenHeader(token)
    })
}

export async function loadUserConfig(
    token: IToken
): Promise<AxiosResponse<object>> {
    return AXIOS_USER_CONFIG.get('/user_config', {
        headers: getTokenHeader(token)
    })
}

export async function saveUserConfig(
    token: IToken,
    userConfig: object
): Promise<AxiosResponse<string | object>> {
    return AXIOS_USER_CONFIG.post('/user_config', userConfig, {
        headers: {
            ...getTokenHeader(token),
            'Content-Type': 'application/json'
        }
    })
}
