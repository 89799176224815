export default function base36Id(): string {
    return (
        Math.ceil(
            Date.now() *
            (
                Math.random() +
                Math.random()
            )
        )
            .toString(36)
    )
}
