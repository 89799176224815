import { ad4mat } from 'ad4mat-daa-model/daa_model'

import { IOptionsData } from 'components/form/typings/form-input-element'
import { HttpMethod } from 'lib/scene-payload-parser/constants'
import { IScenePayload } from 'lib/scene-payload-parser/typings'
import { IMatchRelationEntry } from 'typings/match-relation'

export function parseEntityRelation<T extends ad4mat.API.BASE.Data = ad4mat.API.BASE.Data>(
    entity: T,
    relation: keyof T['relationships']
): Array<T['id']> {
    const relatedIds: Array<T['id']> = []

    if (!entity.relationships) {
        return relatedIds
    }

    if (entity.relationships[relation as string]) {
        const relationEntry: ad4mat.API.BASE.RelationshipEntry = entity.relationships[relation as string]

        if (relationEntry.data instanceof Array) {
            for (const entry of relationEntry.data) {
                relatedIds.push(entry.id)
            }
        } else if (typeof relationEntry.data === 'object' && relationEntry.data !== null) {
            relatedIds.push(relationEntry.data.id)
        }
    }

    return relatedIds
}

export function parseEntitiesRelations<T extends ad4mat.API.BASE.Data = ad4mat.API.BASE.Data>(
    entities: T[],
    relation: keyof T['relationships']
): Array<T['id']> {
    let relationIds: Array<T['id']> = []

    for (const entity of entities) {
        relationIds = relationIds.concat(parseEntityRelation(entity, relation))
    }

    return relationIds
}

export function transformFetchDataToOptionsData<T extends ad4mat.API.BASE.Data = ad4mat.API.BASE.Data>(
    data: T[]
): Array<IOptionsData<T>> {
    const optionsData: Array<IOptionsData<T>> = []

    for (const entity of data) {
        optionsData.push({
            entity,
            label: entity.attributes.name,
            relations: [],
            value: entity.id
        })
    }

    return optionsData
}

export function getIMatchRelationsToDelete(
    relationToDeleteName: string,
    relationTypeToMatch: string,
    matchIds: string[],
    relations: Array<IMatchRelationEntry<ad4mat.API.BASE.Data>>
): Array<IScenePayload<ad4mat.API.BASE.Data>> {
    const deleteRelations: IScenePayload[] = []

    for (const relation of relations) {
        relation.relations.map((t) => {
            if (t.type === relationTypeToMatch && !matchIds.includes(t.id)) {
                deleteRelations.push({
                    httpMethod: HttpMethod.DELETE,
                    id: relation.id,
                    type: relationToDeleteName
                })
            }
        })
    }

    return deleteRelations
}

export function getRelationsToDelete<T extends ad4mat.API.BASE.Data>(
    preSelectedIds: string[],
    currentSelectedIds: string[],
    type: string
): Array<IScenePayload<T>> {
    const deleteRelations: Array<IScenePayload<T>> = []

    for (const id of preSelectedIds) {
        if (!currentSelectedIds.includes(id)) {
            deleteRelations.push({
                httpMethod: HttpMethod.DELETE,
                id,
                type
            })
        }
    }

    return deleteRelations
}

export function getIdByRelation(
    id: string,
    matchType: string,
    relations: Array<IMatchRelationEntry<ad4mat.API.BASE.Data>>
): string {
    for (const entity of relations) {
        for (const relation of entity.relations) {
            if (relation.type === matchType && id === relation.id) {
                return relation.id
            }
        }
    }

    return null
}
