import { IntlShape } from 'react-intl'

import { data } from 'lib/store'
import { TRANSLATION_PROVIDER } from './constants'
import { IProviderAction } from './typings'

export function setProvider(provider: IntlShape): void {
    data.dispatch<IProviderAction>({
        provider,
        type: TRANSLATION_PROVIDER
    })
}

export function getProvider(): IntlShape {
    return data.getState().data.translations.provider
}
