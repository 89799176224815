export function toSingular(input: string): string {
    if (!input.endsWith('s')) {
        return input
    }

    if (input.endsWith('ies')) {
        return input.slice(0, input.length - 3) + 'y'
    }

    return input.slice(0, input.length - 1)
}

export function toPlural(input: string): string {
    if (input.endsWith('s')) {
        return input
    }

    if (input.endsWith('y')) {
        return input.slice(0, input.length - 1) + 'ies'
    }

    return input + 's'
}

export function camelCase(input: string): string {
    const charList = input.split('')
    const length = charList.length
    let result = ''
    let index = -1
    let last = 0

    while (++index < length) {
        if (charList[index] === '_') {
            result = result + (
                (last === 0)
                    ? (
                        charList.slice(last, index)
                            .join('')
                    )
                    : (
                        charList[last].toUpperCase() + charList.slice(last + 1, index)
                            .join('')
                    )
            )
            last = index + 1
        }
    }

    if (last !== 0) {
        result = result + charList[last].toUpperCase() + charList.slice(last + 1)
            .join('')
    }

    if (result.length === 0) {
        result = input
    }

    return result
}

export function snakeCase(input: string, delimiter: string = null): string {
    const isCamelCase = !delimiter

    if (!isCamelCase || input.toLowerCase() === input) {
        return input
            .split(delimiter)
            .join('_')
    }

    const result: string[] = []
    const upperCaseChars = input
        .toUpperCase()
        .split('')
    const chars = input.split('')
    const length = chars.length
    let index = -1

    while (++index < length) {
        if (upperCaseChars[index] !== chars[index]) {
            result.push(chars[index])
            continue
        }

        result.push('_', chars[index].toLowerCase())
    }

    return result.join('')
}

export function capitalizeFirstLetter(word: string) {
    return word
        .charAt(0)
        .toUpperCase() + word.slice(1)
}
