import { PermissionType } from 'services/oauth/constants'

export const errorName = 'ERR_NO_PERM'

export default class PermissionError extends Error {
    public readonly status: number = -1
    public readonly resource: string
    public readonly permissionType: PermissionType

    public constructor(resource: string, permissionType: PermissionType) {
        super()

        this.name = errorName
        this.resource = resource
        this.permissionType = permissionType
        this.message = `current user isn't permitted to ${this.accessLevelLabel(permissionType)} "${resource}"`
    }

    private accessLevelLabel(permissionType: PermissionType): string {
        switch (permissionType) {
            case PermissionType.Read:
                return 'read'
            case PermissionType.Write:
                return 'write'
            case PermissionType.Delete:
                return 'delete'
            default:
                return 'access'
        }
    }
}
