import { createIntl, createIntlCache, IntlShape } from 'react-intl'

import { getProvider } from 'data/translations/index'
import { Language } from 'services/storage-access/typings'

const cache = createIntlCache()

export function createIntlProvider(
    locale: Language,
    messages: object
): IntlShape {
    return createIntl(
        {
            locale,
            messages: messages as Record<string, string>
        },
        cache
    )
}

export default function translationMessage(
    translationId: string,
    values?: Record<string, string | number | boolean | null | undefined>
): string {
    const provider = getProvider()

    if (provider) {
        return provider.formatMessage(
            {
                defaultMessage: translationId,
                id: translationId
            },
            values
        )
    }

    return translationId
}
