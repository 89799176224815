import { user } from 'lib/store'
import { IState } from 'services/user-session/typings'
import { PermissionType } from '../oauth/constants'

export function hasDaaPermission(resource: string, type: PermissionType): boolean {
    return hasPermission('ad4mat.api.' + resource, type)
}

export function hasSaaPermission(resource: string, type: PermissionType): boolean {
    return hasPermission('ad4mat.one.api.' + resource, type)
}

export function hasPermission(permissionKey: string, type: PermissionType): boolean {
    const permission = (user.getState() as IState).user.permissions.get(permissionKey)

    return permission !== undefined && permission.includes(type)
}

export function hasDaaPermissions(resources: string[], type: PermissionType): boolean {
    for (const resource of resources) {
        if (!hasDaaPermission(resource, type)) {
            return false
        }
    }

    return true
}
