import { ad4mat } from 'ad4mat-daa-model/daa_model'

import { CREDENTIALS } from '../../../services/session/constants'
import { fetch } from 'services/daa'
import { getPreference, loadPreferenceState, setPermissions, setToken, setUserData, getToken } from '../../../services/user-session'
import { IToken, IoAuthRole } from 'services/oauth/typings'
import { RESOURCE_SERVER_CREDENTIALS } from '../../../services/oauth/constants'
import { TokenType } from '../../../services/user-session/constants'
import { user } from 'lib/store'
import * as oauth from '../../../services/oauth/api'

interface ILoginData {
    user: string,
    pw: string
}

async function requestTokens(tokenTypes: TokenType[], loginData: ILoginData): Promise<IToken[]> {
    const tokenList: Array<Promise<IToken>> = []

    for (const tokenType of tokenTypes) {
        const tokenCredentials = CREDENTIALS.get(tokenType)

        tokenList.push(oauth.getToken({
            client_id: tokenCredentials.client_id,
            client_secret: tokenCredentials.client_secret,
            password: loginData.pw,
            username: loginData.user
        }))
    }

    return Promise.all(tokenList)
}

export function hasTokens(): boolean {
    const validTokens: TokenType[] = []
    const tokens = user.getState().user.tokens
    const keepLoggedIn = getPreference('kli') === true

    for (const [type, token] of tokens.entries()) {
        if (keepLoggedIn || oauth.validateToken(token)) {
            validTokens.push(type)
        }
    }

    return validTokens.includes(TokenType.DataAccessApi) &&
        validTokens.includes(TokenType.UserAuth)
}

async function fetchUserMeta(roles: IoAuthRole[]): Promise<void> {
    const isAdvertiser = roles.some((role) => role.name.includes('advertiser'))

    if (isAdvertiser) {
        const result = await fetch<ad4mat.API.GET.Advertiser.Data>({ resource: 'advertisers' })

        if (result.data.length !== 0) {
            setUserData({ advertiserId: result.data[0].id })
        }
    }
}

export async function fetchUser(): Promise<void> {
    const userInfo = await Promise.all([
        oauth.tokenInfo({
            ...RESOURCE_SERVER_CREDENTIALS,
            token: getToken(TokenType.DataAccessApi).token
        }),
        oauth.tokenUser(getToken(TokenType.UserAuth))
    ])

    setPermissions(userInfo[0].permissions)
    setUserData(userInfo[1])
    await fetchUserMeta(userInfo[1].roles)
}

export default async function fetchLogin(loginData: ILoginData): Promise<boolean> {
    const tokenTypes: TokenType[] = [
        TokenType.DataAccessApi,
        TokenType.UserAuth
    ]
    const optionalTypes: TokenType[] = [
        TokenType.AdminAuth,
        TokenType.StorageAccessApi
    ]

    const tokens = await requestTokens(tokenTypes, loginData)

    setToken(TokenType.DataAccessApi, tokens[0])
    setToken(TokenType.UserAuth, tokens[1])

    try {
        const optionalTokens = await requestTokens(optionalTypes, loginData)

        setToken(TokenType.AdminAuth, optionalTokens[0])
        setToken(TokenType.StorageAccessApi, optionalTokens[1])

    } catch (err) {
        // these tokens are optional. which means that when a error occurs while trying to receive one isnt fatal for the login
    }

    try {
        await loadPreferenceState()
    } catch (err) {
        console.error(err)
    }

    return true
}
