import * as React from 'react'
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom'

import { routeExists } from 'lib/route-util'
import routes from 'lib/routes'

type Props = RouteComponentProps<any> & React.Props<object>

function NotFound(props: Props) {
    const currentPath = props.location.pathname

    return (
        <Redirect
            to={{
                pathname: '/',
                state: {
                    returnTo: (currentPath !== '/logout' && routeExists(currentPath, routes)) ? currentPath : null
                }
            }}
        />
    )
}

export default withRouter(NotFound)
