import { Language } from 'services/storage-access/typings'

export default function languageShortIso(lang: string): Language {
    if (!lang) {
        return null
    }

    const dashIndex = lang.indexOf('-')

    if (dashIndex !== -1) {
        return lang.slice(0, dashIndex) as Language
    }

    return lang as Language
}
