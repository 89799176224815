import { LOADING, LOADING_PROGRESS, LOADING_RESET } from './constants'
import { ILoadingAction, ILoadingProgressAction, IState } from './typings'

function getDefaultState(): IState {
    return {
        isLoading: false,
        progress: 0,
        count: 0
    }
}

export default function reducer(
    state: IState = getDefaultState(),
    action: ILoadingAction | ILoadingProgressAction
): IState {
    switch (action.type) {
        case LOADING:
            const count = ((action as ILoadingAction).isLoading) ? state.count + 1 : Math.max(state.count - 1, 0)

            return {
                isLoading: count !== 0,
                progress: (count > 0) ? state.progress : 100,
                count
            }
        case LOADING_PROGRESS:
            return {
                isLoading: state.isLoading,
                progress: (action as ILoadingProgressAction).progress,
                count: state.count
            }
        case LOADING_RESET:
            return getDefaultState()
        default:
            return state
    }
}
