import { Provider } from 'react-redux'
import { RawIntlProvider } from 'react-intl'
import { Unsubscribe } from 'redux'
import * as React from 'react'

import { getProvider, setProvider } from 'data/translations/index'
import { setupUserLocale } from 'lib/lang'
import { errorMessage } from 'lib/notification-api'
import { data, user } from 'lib/store'
import { createIntlProvider } from 'lib/translate'
import logout from 'scenes/logout/services/logout'
import { SessionEventTypes } from 'services/session/constants'
import { RemoveHandler } from 'services/session/typings'
import { fetchTranslations } from 'services/storage-access/index'
import { ILanguageTranslation, ITranslations, Language } from 'services/storage-access/typings'
import { StateLoadType } from 'services/user-session/constants'
import { getPreference, loadState, session } from 'services/user-session/index'
import Routes from '../routes'

interface IState extends Object {
    lang: Language
    render: boolean
    translations: ITranslations
}

type Props = React.Props<any>

export default class Root extends React.Component<Props, IState> {
    private readonly removeHandler: RemoveHandler
    private readonly removeSubscription: Unsubscribe
    private isFetching: boolean = false
    private isComponentMounted: boolean = false

    public constructor(props: Props, context: object) {
        super(props, context)

        this.state = {
            // should be initialized with the user language (navigator.language) [KM-380]
            lang: 'en',
            render: false,
            translations: null
        }

        this.setup()
            .catch(errorMessage)
        this.removeHandler = session.addHandler(SessionEventTypes.LOGOUT, () => {
            logout(true)
                .catch(errorMessage)
        })
        this.removeSubscription = user.subscribe(this.updateTranslations.bind(this) as (initial?: boolean) => Promise<boolean>)
    }

    public componentDidMount(): void {
        this.isComponentMounted = true
    }

    public componentWillUnmount() {
        this.removeHandler()
        this.removeSubscription()
        this.isComponentMounted = false
    }

    public shouldComponentUpdate(_nextProps: Props, nextState: IState): boolean {
        return (nextState.render !== this.state.render ||
            (
                nextState.render && (
                    nextState.lang !== this.state.lang ||
                    nextState.translations !== this.state.translations
                )
            )
        )
    }

    public render(): React.ReactElement<HTMLElement> {
        return this.state.render
            ? (
                <Provider store={data}>
                    <RawIntlProvider value={getProvider()}>
                        <Routes />
                    </RawIntlProvider>
                </Provider>
            ) : null
    }

    private async setup() {
        if (await loadState() === StateLoadType.UserPreference) {
            if (await this.updateTranslations(true)) {
                this.setShouldRender(true)
            }
        } else {
            if (await this.initLanguage()) {
                this.setShouldRender(true)
            }
        }
    }

    private async initLanguage(): Promise<boolean> {
        return this.fetchTranslations(setupUserLocale() as Language)
    }

    private async updateTranslations(initial: boolean = false): Promise<boolean> {
        const userLang = getPreference<Language>('lang')

        if (
            (!initial && !this.state.render) ||
            (userLang === this.state.lang && this.state.translations !== null) ||
            this.isFetching
        ) {
            return false
        }

        this.isFetching = true

        return this.fetchTranslations(setupUserLocale(userLang) as Language)
    }

    private async fetchTranslations(lang: Language): Promise<boolean> {
        this.isFetching = true

        const languageTranslation = await this.tryFetch(lang)

        this.isFetching = false

        if (Array.isArray(languageTranslation) && languageTranslation[0] !== undefined) {
            const translations = languageTranslation[0].translations

            setProvider(createIntlProvider(lang, translations))

            if (this.isComponentMounted) {
                this.setState({ lang, translations })
            } else {
                // @ts-ignore
                this.state.lang = lang
                // @ts-ignore
                this.state.translations = translations
            }

            return true
        }

        return false
    }

    private async tryFetch(lang: Language): Promise<ILanguageTranslation[]> {
        try {
            return fetchTranslations(lang)
        } catch (err) {
            console.error(err)

            return null
        }
    }

    private setShouldRender(shouldRender: boolean): void {
        if (this.isComponentMounted) {
            this.setState({ render: shouldRender })
        } else {
            // @ts-ignore
            this.state.render = shouldRender
        }
    }
}
