import { ad4mat } from 'ad4mat-daa-model/daa_model'
import { AnyArgsFn } from '@ad4mat/javascript-utilities/dist/typings/function'

import arrayUniqueValue from 'lib/array-unique-value'
import { fetch } from 'services/daa'
import {
    IFetchConfig,
    IFetchResponse
} from 'services/daa/typings'
import {
    DaaFetchConfigList,
    DaaFetchResponse,
    IDaaFetchConfig,
    IDaaFetchRelationConfig,
    IDaaFetchResponse
} from 'typings/fetch-config'
import { camelCase } from './daa-resource-name'
import { parseEntitiesRelations } from './parse-relation'
import { stripConditionals } from './strip-query-conditionals'

type DataArray<T extends ad4mat.API.BASE.Data> = T[][]

interface IFetchTree<DL extends DataArray<ad4mat.API.BASE.Data>> {
    children: Array<IFetchTree<DL>> | null
    current: IDaaFetchConfig<DL[number][number]> | IDaaFetchRelationConfig<DL>
    parent: IFetchTree<DL> | null
}

type FetchConfig<T extends ad4mat.API.BASE.Data> = IFetchConfig<T>

class DaaFetch<DL extends DataArray<ad4mat.API.BASE.Data>> {
    private static readonly PAGE_SIZE_RELATION = 10
    private static readonly PAGE_SIZE_NO_RELATION = 80

    private readonly fetchTree: Array<IFetchTree<DL>>
    private readonly fetchErrorFatal: boolean
    private readonly responseMap: Map<string, IDaaFetchResponse<DL>> = new Map()
    private readonly entityMap: Map<string, ad4mat.API.BASE.Data> = new Map()
    private readonly requestLock: Map<string, Promise<DL[number][number]>> = new Map()

    public constructor(configList: DaaFetchConfigList<DL>, fetchErrorFatal: boolean = true) {
        this.fetchTree = DaaFetch.createFetchTree(configList)
        this.fetchErrorFatal = fetchErrorFatal
    }

    private static createFetchTree<DL extends DataArray<ad4mat.API.BASE.Data>>(
        configList: DaaFetchConfigList<DL>,
        parent: IFetchTree<DL> = null
    ): Array<IFetchTree<DL>> {
        const fetchTree: Array<IFetchTree<DL>> = []

        for (const current of configList) {
            if (!current) {
                continue
            }

            const treeBranch: IFetchTree<DL> = {
                children: null,
                current,
                parent
            }

            if (current.relations && current.relations.length !== 0) {
                treeBranch.children = DaaFetch.createFetchTree(current.relations, treeBranch)
            }

            fetchTree.push(treeBranch)
        }

        return fetchTree
    }

    private static filterData(data: ad4mat.API.BASE.Data[]): ad4mat.API.BASE.Data[] {
        const result: ad4mat.API.BASE.Data[] = []

        for (const entry of data) {
            if (entry) {
                result.push(entry)
            }
        }

        return result
    }

    public initFetch(): Promise<DaaFetchResponse<DL>> {
        const fetchList: Array<Promise<IDaaFetchResponse<DL>>> = []

        for (const entry of this.fetchTree) {
            fetchList.push(this.fetch(entry))
        }

        return Promise.all(fetchList)
    }

    private splitFetch(entry: IFetchTree<DL>): Array<Promise<IDaaFetchResponse<DL>>> {
        const configs = this.createSplitFetchConfig(entry)
        const responseList: Array<Promise<IDaaFetchResponse<DL>>> = []

        for (const config of configs) {
            responseList.push(this.fetch(config))
        }

        return responseList
    }

    private async paginatedFetch(entry: IFetchTree<DL>): Promise<Array<Promise<IDaaFetchResponse<DL>>>> {
        const responseList: Array<Promise<IDaaFetchResponse<DL>>> = []
        const baseConfig = this.buildFetchConfig(entry)

        if (this.isEmptyConfig(entry, baseConfig)) {
            return responseList
        }

        const pageSize = (entry.children && entry.children.length !== 0) ? DaaFetch.PAGE_SIZE_RELATION : DaaFetch.PAGE_SIZE_NO_RELATION
        const initialRes = await fetch({
            ...this.buildFetchConfig(entry),
            pagination: {
                pageSize: 1,
                page: 1
            },
            relations: {
                none: true
            },
            sortBy: null
        })

        if (initialRes.pagination) {
            const pageCount = Math.ceil(initialRes.pagination.overallEntries / pageSize)

            for (let page = 1; page <= pageCount; page++) {
                responseList.push(this.fetch(this.createPaginatedFetchConfig(entry, page, pageSize)))
            }
        }

        return responseList
    }

    private async fetch(entry: IFetchTree<DL>): Promise<IDaaFetchResponse<DL>> {
        let config: FetchConfig<DL[number][number]> | null = null
        let response: IDaaFetchResponse<DL> = null

        if ((entry.current as IDaaFetchRelationConfig).fetchRelation === false) {
            response = this.buildResponse(entry.current.resource)
        } else {
            config = this.buildFetchConfig(entry)

            if (this.isEmptyConfig(entry, config)) {
                response = this.buildResponse(entry.current.resource)
            } else {
                response = await this.handleResponse(
                    entry.current.resource,
                    (this.isLockable(config))
                        ? this.partialFetch(config)
                        : fetch<DL[number][number]>(config)
                )
            }
        }

        this.responseMap.set(this.getResponseMapKey(entry), response)

        if (entry.children) {
            const childFetchList: Array<Promise<IDaaFetchResponse<DL>>> = []

            for (const child of entry.children) {
                if ((child.current as IDaaFetchRelationConfig<DL>).splitRequests) {
                    for (const result of this.splitFetch(child)) {
                        childFetchList.push(result)
                    }
                } else if ((child.current as IDaaFetchRelationConfig<DL>).shouldPaginate) {
                    for (const result of await this.paginatedFetch(child)) {
                        childFetchList.push(result)
                    }
                } else {
                    childFetchList.push(this.fetch(child))
                }
            }

            response.relations = await Promise.all(childFetchList)
        }

        return response
    }

    private async partialFetch(config: FetchConfig<DL[number][number]>): Promise<IFetchResponse<DL[number][number]>> {
        const lockedFns: Map<string, AnyArgsFn<void, [DL[number][number]]>> = new Map()
        const cachedEntities: Map<string, DL[number][number]> = new Map()
        const lockList: Array<Promise<DL[number][number]>> = []
        const idList: string[] = []
        const idCount = config.ids.length
        let response: IFetchResponse<DL[number][number]>

        for (const id of config.ids) {
            const entity = this.entityMap.get(this.getEntityMapKeyByConfig(config, id))

            if (entity) {
                cachedEntities.set(id, entity)
                continue
            }

            const key = this.getLockKey(config, id)
            const lock = this.requestLock.get(key)

            if (lock) {
                lockList.push(lock)
            } else {
                let resolve: AnyArgsFn<void, [DL[number][number]]>

                idList.push(id)
                this.requestLock.set(key, new Promise((res) => { resolve = res }))
                lockedFns.set(id, (fetchedEntity: DL[number][number]) => {
                    resolve(fetchedEntity)
                    this.requestLock.delete(key)
                })
            }
        }

        if (idList.length !== 0) {
            response = await fetch({
                ...config,
                ids: idList
            })

            for (const entry of response.data) {
                this.entityMap.set(this.getEntityMapKeyByConfig(config, entry.id), entry)
                cachedEntities.set(entry.id, entry)
                lockedFns.get(entry.id)(entry)
            }
        } else {
            response = {
                data: [],
                pagination: {
                    currentPage: (config.pagination) ? config.pagination.page : 1,
                    overallEntries: idCount,
                    totalPages: (config.pagination) ? Math.ceil(idCount / config.pagination.pageSize) : 1
                }
            }
        }

        if (lockList.length !== 0) {
            for (const entity of await Promise.all(lockList)) {
                cachedEntities.set(entity.id, entity)
            }
        }

        const result = Array(idCount)

        for (let index = 0; index < idCount; index++) {
            result[index] = cachedEntities.get(config.ids[index])
        }

        // since the user can be restricted and not every given ID
        // will return an entity there can be `undefined` entries
        // which must be removed
        response.data = DaaFetch.filterData(result)

        return response
    }

    private async handleResponse(
        resource: string,
        promise: Promise<IFetchResponse<DL[number][number]>>
    ): Promise<IDaaFetchResponse<DL>> {
        try {
            return this.buildResponse(resource, await promise)
        } catch (err) {
            if (this.fetchErrorFatal) {
                throw err
            }

            return this.buildResponse(resource)
        }
    }

    private buildResponse(
        resource: string,
        response?: IFetchResponse<DL[number][number]>
    ): IDaaFetchResponse<DL> {
        if (!response) {
            return {
                data: null,
                pagination: null,
                relations: null,
                type: resource
            }
        }

        return {
            data: response.data,
            pagination: response.pagination,
            relations: null,
            type: resource
        }
    }

    private buildFetchConfig(treeBranch: IFetchTree<DL>): FetchConfig<DL[number][number]> {
        const config = treeBranch.current

        return {
            ids: this.buildFetchConfigIdList(treeBranch),
            pagination: config.pagination,
            query: this.buildFetchConfigQuery(treeBranch),
            relations: this.buildFetchConfigRelations(treeBranch),
            resource: config.resource,
            search: config.search,
            sortBy: config.sortBy
        }
    }

    private buildFetchConfigRelations(branch: IFetchTree<DL>): IFetchConfig<DL[number][number]>['relations'] {
        const config = branch.current
        const relations: Array<keyof DL[number][number]['relationships']> = []

        if (Array.isArray(config.relations)) {
            for (const relation of config.relations) {
                if (relation.includeRelation !== false) {
                    relations.push(relation.resource as keyof DL[number][number]['relationships'])
                }
            }
        }

        if (
            (branch.current as IDaaFetchRelationConfig<DL>).includeRelation === false &&
            branch.parent !== null &&
            !relations.includes(branch.parent.current.resource as keyof DL[number][number]['relationships'])
        ) {
            relations.push(branch.parent.current.resource as keyof DL[number][number]['relationships'])
        }

        return {
            include: relations
        }
    }

    private buildFetchConfigIdList(treeBranch: IFetchTree<DL>): string[] | null {
        if (
            (treeBranch.current as IDaaFetchRelationConfig<DL>).splitRequests ||
            (treeBranch.current as IDaaFetchRelationConfig<DL>).generateIdList === false
        ) {
            return null
        }

        const parentResponse = this.responseMap.get(this.getResponseMapKey(treeBranch.parent))
        const config = treeBranch.current

        if (
            (!config.ids && !parentResponse) ||
            ((config as IDaaFetchRelationConfig<DL>).includeRelation === false && !config.ids)
        ) {
            return null
        }

        const parentIdList: string[] = (parentResponse && parentResponse.data && parentResponse.data.length !== 0)
            ? parseEntitiesRelations<DL[number][number]>(
                parentResponse.data,
                config.resource as keyof DL[number][number]['relationships']
            )
            : null

        if (config.ids) {
            if (parentIdList !== null) {
                return arrayUniqueValue(config.ids.concat(parentIdList)) as string[]
            }

            return arrayUniqueValue(config.ids) as string[]
        }

        if (parentIdList !== null) {
            return arrayUniqueValue(parentIdList) as string[]
        }

        return null
    }

    private buildFetchConfigQuery(current: IFetchTree<DL>): string {
        const config = current.current as IDaaFetchRelationConfig<DL>

        if (config.fetchRelation === false) {
            return null
        }

        if (config.splitRequests || config.generateQuery === false || config.includeRelation !== false) {
            return stripConditionals(config.query)
        }

        const parentConfig = (current.parent !== null) ? current.parent.current as IDaaFetchRelationConfig<DL> : null
        const parentResponse = (current.parent !== null) ? this.responseMap.get(this.getResponseMapKey(current.parent)) : null

        if (config.generateQuery || config.includeRelation === false) {
            if (
                parentConfig
                && parentConfig.fetchRelation !== false
                && (
                    !parentResponse
                    || !parentResponse.data
                    || parentResponse.data.length === 0
                )
            ) {
                return null
            }

            const query = this.createFetchParentQuery(current)

            if (!query) {
                return null
            }

            return stripConditionals(
                query + ';' + (config.query || '')
            )
        }

        return null
    }

    private createFetchParentQuery(treeBranch: IFetchTree<DL>): string {
        let mapKey: string
        let response: IDaaFetchResponse<DL>
        let currentBranch: IFetchTree<DL> | null = treeBranch
        let prevBranch: IFetchTree<DL> | null = null
        let queryString = ''

        while (currentBranch !== null) {
            if (
                (prevBranch && (currentBranch.current as IDaaFetchRelationConfig<DL>).queryRelation && !currentBranch.parent)
                || (currentBranch === treeBranch && (currentBranch.current as IDaaFetchRelationConfig<DL>).queryRelation)
            ) {
                queryString = queryString + '.' + (
                    (currentBranch.current as IDaaFetchRelationConfig<DL>).queryRelation ||
                    currentBranch.current.resource
                )
            }

            if ((currentBranch.current as IDaaFetchRelationConfig<DL>).fetchRelation !== false) {
                mapKey = this.getResponseMapKey(currentBranch)

                if (this.responseMap.has(mapKey)) {
                    response = this.responseMap.get(mapKey)

                    if (response.data !== null && response.data.length !== 0) {
                        break
                    }
                }
            }

            prevBranch = currentBranch
            currentBranch = currentBranch.parent
        }

        if (!response || response.data === null || response.data.length === 0) {
            return null
        }

        queryString = queryString.slice(1) + (
            // tslint:disable-next-line:newline-per-chained-call
            `.id=in=(${response.data.map((entry) => entry.id).join(',')})`
        )

        return queryString
    }

    private createPaginatedFetchConfig(entry: IFetchTree<DL>, page: number, pageSize: number): IFetchTree<DL> {
        const treeBranch: IFetchTree<DL> = {
            ...entry,
            children: null,
            current: {
                ...entry.current,
                pagination: {
                    pageSize,
                    page
                }
            }
        }

        if (entry.children !== null) {
            this.moveChildNodes(entry, treeBranch)
        }

        return treeBranch
    }

    private moveChildNodes(entry: IFetchTree<DL>, parent: IFetchTree<DL>): void {
        const length = entry.children.length
        const result: Array<IFetchTree<DL>> = Array(length)
        let current: IFetchTree<DL>
        let nextParent: IFetchTree<DL>

        for (let index = 0; index < length; index++) {
            current = entry.children[index]
            nextParent = {
                ...current,
                children: null,
                parent
            }

            if (current.children) {
                this.moveChildNodes(current, nextParent)
            }

            result[index] = nextParent
        }

        parent.children = result
    }

    private createSplitFetchConfig(entry: IFetchTree<DL>): Array<IFetchTree<DL>> {
        const configList: Array<IFetchTree<DL>> = []
        const response = this.responseMap.get(this.getResponseMapKey(entry.parent))

        if (!response || !response.data || response.data.length === 0) {
            return configList
        }

        const config = entry.current as IDaaFetchRelationConfig<DL>

        for (const data of response.data) {
            configList.push({
                ...entry,
                current: {
                    ...config,
                    query: stripConditionals(
                        `${this.getSplitQueryResource(entry)}.id==${data.id};`
                        + stripConditionals(config.query || '')
                    ),
                    relations: this.getSplitFetchRelations(entry)
                }
            })
        }

        return configList
    }

    private getSplitQueryResource(entry: IFetchTree<DL>): string {
        return ((entry.current as IDaaFetchRelationConfig).queryRelation)
            ? ((entry.current as IDaaFetchRelationConfig).queryRelation)
            : ((entry.parent.current as IDaaFetchRelationConfig).queryRelation)
                ? (entry.parent.current as IDaaFetchRelationConfig).queryRelation
                : camelCase(entry.parent.current.resource)
    }

    private getResponseMapKey(treeBranch: IFetchTree<DL>): string {
        let key = ''
        let current = treeBranch

        while (current !== null) {
            key += current.current.resource

            if ((current.current as IDaaFetchRelationConfig).splitRequests) {
                key += current.current.query as string
            } else if ((current.current as IDaaFetchRelationConfig).shouldPaginate && current.current.pagination) {
                key += current.current.pagination.page
            }

            current = current.parent
        }

        return key
    }

    private getSplitFetchRelations(entry: IFetchTree<DL>): IDaaFetchRelationConfig<DL>['relations'] {
        const currentRelations = entry.current.relations as Array<IDaaFetchRelationConfig<DL>>

        if (!entry.parent) {
            return currentRelations
        }

        const parentRelation: Array<IDaaFetchRelationConfig<DL>> = [{
            fetchRelation: false,
            resource: entry.parent.current.resource
        }]

        return (currentRelations && currentRelations.length !== 0)
            ? currentRelations.concat(parentRelation)
            : parentRelation
    }

    private getEntityMapKeyByConfig(config: FetchConfig<DL[number][number]>, id: string): string {
        return config.resource + id + this.getRelationKey(config)
    }

    private isLockable(current: FetchConfig<DL[number][number]>): boolean {
        return !current.query && !current.search && current.ids && current.ids.length !== 0
    }

    private getLockKey(config: FetchConfig<DL[number][number]>, id: string): string {
        return config.resource + id + this.getRelationKey(config)
    }

    private getRelationKey(config: FetchConfig<DL[number][number]>): string {
        let relationkey = ''

        if (typeof config.relations === 'object') {
            if (config.relations.exclude) {
                relationkey += '_ex_' + config.relations.exclude.join('')
            } else if (config.relations.include) {
                relationkey += '_in_' + config.relations.include.join('')
            } else if (config.relations.none) {
                relationkey += '_no_'
            }
        }

        return relationkey
    }

    private isEmptyConfig(entry: IFetchTree<DL>, config: FetchConfig<DL[number][number]>): boolean {
        return (
            (
                (entry.current as IDaaFetchRelationConfig).includeRelation === false && (
                    !config.query && (
                        !config.ids || config.ids.length === 0
                    )
                )
            ) ||
            (
                entry.parent !== null &&
                (
                    (
                        (entry.parent.current as IDaaFetchRelationConfig).fetchRelation === false &&
                        !config.query
                    ) ||
                    (
                        (entry.parent.current as IDaaFetchRelationConfig).fetchRelation !== false &&
                        (!config.ids || config.ids.length === 0) &&
                        !config.query
                    )
                )
            )
        )
    }
}

export default function daaFetchInitiator<DL extends DataArray<ad4mat.API.BASE.Data>>(
    configList: DaaFetchConfigList<DL>
): Promise<DaaFetchResponse<DL>> {
    return (new DaaFetch(configList)).initFetch()
}
