import { IToken, PermissionMap } from 'services/oauth/typings'
import { IPreference, IUserData, IUserSessionState } from 'services/user-session/typings'
import {
    DESTROY_STATE,
    REMOVE_PREFERENCE,
    REMOVE_TOKEN,
    SET_PERMISSIONS,
    SET_PREFERENCE,
    SET_TOKEN,
    SET_USER,
    TokenType
} from './constants'

function reduceSetToken(state: IUserSessionState, tokenType: TokenType, token: IToken): IUserSessionState {
    const entries = state.tokens.entries()
    const mapEntries: Array<[TokenType, IToken]> = [[tokenType, token]]

    for (const entry of entries) {
        if (entry[0] !== tokenType) {
            mapEntries.push(entry)
        }
    }

    return {
        ...state,
        tokens: new Map(mapEntries)
    }
}

function reduceSetPreference(state: IUserSessionState, preference: IPreference): IUserSessionState {
    return {
        ...state,
        preferences: {
            ...state.preferences,
            [preference.name]: preference.value
        }
    }
}

function reduceRemovePreference(state: IUserSessionState, preference: string): IUserSessionState {
    const keys = Object.keys(state.preferences)
    const newPreferences: IUserSessionState['preferences'] = {}

    for (const key of keys) {
        if (key !== preference) {
            newPreferences[key] = state.preferences[key]
        }
    }

    return {
        ...state,
        preferences: newPreferences
    }
}

function reduceSetUser(state: IUserSessionState, userData: IUserData): IUserSessionState {
    return {
        ...state,
        data: {
            ...state.data,
            ...userData
        }
    }
}

function reduceSetPermissions(state: IUserSessionState, permissions: PermissionMap): IUserSessionState {
    return {
        ...state,
        permissions
    }
}

function removeToken(state: IUserSessionState, tokenType: TokenType): IUserSessionState {
    const entries = state.tokens.entries()
    const mapEntries = []

    for (const entry of entries) {
        if (entry[0] === tokenType) {
            continue
        }

        mapEntries.push(entry)
    }

    return {
        ...state,
        tokens: new Map(mapEntries)
    }
}

function reduceDestroyState(): IUserSessionState {
    return {
        data: {},
        permissions: new Map(),
        preferences: {},
        tokens: new Map()
    }
}

export default function reduce(
    state: IUserSessionState = reduceDestroyState(),
    action: { type: string } & Record<string, unknown>
) {
    switch (action.type) {
        case DESTROY_STATE:
            return reduceDestroyState()
        case REMOVE_TOKEN:
            return removeToken(state, action.tokenType as TokenType)
        case SET_PREFERENCE:
            return reduceSetPreference(state, action.preference as IPreference)
        case SET_TOKEN:
            return reduceSetToken(state, action.tokenType as TokenType, action.token as IToken)
        case SET_USER:
            return reduceSetUser(state, action.userData)
        case SET_PERMISSIONS:
            return reduceSetPermissions(state, action.permissions as PermissionMap)
        case REMOVE_PREFERENCE:
            return reduceRemovePreference(state, action.preference as string)
        default:
            return state
    }
}
