import IziToast, { IziToast as IziToastType, IziToastSettings } from 'izitoast'

import 'izitoast/dist/css/iziToast.min.css'

import { isDevelopment } from './env'
import translate from './translate'

IziToast.settings({
    closeOnClick: true,
    closeOnEscape: false,
    drag: true,
    message: translate('ERR_OCCURRED'),
    pauseOnHover: true,
    position: 'topRight',
    progressBar: true
})

export enum NotificationType {
    SUCCESS,
    WARNING,
    ERROR,
    INFO
}

export function missingInput(inputFieldName: string): void {
    const message = translate(`ERR_INPUT_${inputFieldName}`)

    IziToast.error({
        message,
        title: translate('INPUT_MISSING')
    })
}

export function errorMessage(
    err: Error | null,
    title: string = 'ERR_DEFAULT_TITLE',
    message: string = 'ERR_DEFAULT_DESCRIPTION'
): void {
    const hasError = !!err

    if (hasError) {
        console.error(err)
    }

    IziToast.error({
        message: (isDevelopment && hasError) ? err.message : translate(message),
        title: (isDevelopment && hasError) ? err.name : translate(title)
    })
}

function callInstanceByType(type: NotificationType, config: IziToastSettings): void {
    switch (type) {
        case NotificationType.SUCCESS:
            IziToast.success(config)
            break
        case NotificationType.WARNING:
            IziToast.warning(config)
            break
        case NotificationType.ERROR:
            IziToast.error(config)
            break
        default:
            IziToast.info(config)
    }
}

export function notifyUser(title: string, message: string, type: NotificationType = NotificationType.INFO): void {
    callInstanceByType(type, {
        message: translate(message),
        title: translate(title)
    })
}

export function userConfirm(
    title: string,
    message: string,
    confirmLabel: string,
    cancelLabel: string,
    type: NotificationType = NotificationType.INFO
): Promise<boolean> {
    return new Promise((resolve: ResolveRejectFn<boolean>) => {
        function handleConfirmButton(instance: IziToastType, element: HTMLDivElement): void {
            instance.hide(null, element, '1')
        }

        function handleCancelButton(instance: IziToastType, element: HTMLDivElement): void {
            instance.hide(null, element, '0')
        }

        function closeHandler(_settings: IziToastSettings, _element: HTMLDivElement, closedBy: string): void {
            resolve(closedBy === '1')
        }

        callInstanceByType(type, {
            buttons: [
                [
                    `<button class="btn btn-primary">${translate(confirmLabel)}</button>`,
                    handleConfirmButton,
                    true
                ],
                [
                    `<button class="btn btn-secondary">${translate(cancelLabel)}</button>`,
                    handleCancelButton,
                    false
                ]
            ],
            close: false,
            closeOnClick: false,
            closeOnEscape: false,
            drag: true,
            message: translate(message),
            onClosing: closeHandler,
            overlay: false,
            progressBar: false,
            timeout: false,
            title: translate(title)
        })
    })
}
