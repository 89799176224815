import PayloadInterpreter from '@ad4mat/javascript-utilities/dist/esm/lib/api/daa-payload-interpreter'
import {
    ICreatePayload as IDaaCreatePayload,
    IUpdatePayload as IDaaUpdatePayload
} from '@ad4mat/javascript-utilities/dist/typings/daa-payload.d'
import { ad4mat } from 'ad4mat-daa-model/daa_model'

import { AxiosResponse } from 'axios'
import { stripConditionals } from 'lib/strip-query-conditionals'
import { PermissionType } from '../oauth/constants'
import { IToken } from '../oauth/typings'
import {
    AXIOS,
    checkPermission,
    create as daaCreate,
    destroy as daaDestroy,
    getDefaultHeader,
    handleDaaResponseError,
    update as daaUpdate
} from './api'
import {
    EntityAttributes,
    EntityRelationships,
    ICreatePayload,
    IErrorResponse,
    IFetchConfig,
    IFetchPaginationConfig,
    IFetchRelationConfig,
    IFetchResponse,
    IFetchSearchConfig,
    IFetchSortByConfig,
    IPayload,
    IUpdatePayload,
    PayloadRelationships
} from './typings'

type FetchQueryValue = Array<[string, string]>
type MultiSingleGenericResponse<T> = ad4mat.API.BASE.Response<T> | ad4mat.API.BASE.ResponseArray<T>

const MAX_ID_LENGTH = 100

function createFetchPaginationQuery(config: IFetchPaginationConfig): FetchQueryValue {
    if (!config) {
        return null
    }

    const queryValues: FetchQueryValue = []

    queryValues.push([
        'page[number]',
        (config.page) ? String(config.page) : '1'
    ])

    if (config.pageSize !== undefined) {
        queryValues.push([
            'page[size]',
            String(config.pageSize)
        ])
    }

    return queryValues.length >= 1 ? queryValues : null
}

function createSortByQuery(config: IFetchSortByConfig): FetchQueryValue {
    if (!config) {
        return null
    }

    const queryValues: FetchQueryValue = []

    if (config.attributeName !== undefined) {
        queryValues.push([
            'order[attr]',
            config.attributeName
        ])
    }

    if (config.order !== undefined && (config.order === 'ASC' || config.order === 'DESC')) {
        queryValues.push([
            'order[type]',
            config.order
        ])
    }

    return queryValues.length === 2 ? queryValues : null
}

function createSearchQuery(queryString: string, config: IFetchSearchConfig, suffix: string = ')'): string {
    if (!config) {
        return ''
    }

    if (config.attributes !== undefined && config.value !== undefined) {
        const attributes = config.attributes
        const length = attributes.length
        let searchQuery = queryString
        let index = -1
        let searchValues: string[]

        if (config.fullText) {
            searchValues = config.value.trim()
                .split(/\s/)
                .map((value) => `'*${value}*'`)

        } else if (config.wildcard) {
            searchValues = [`'*${config.value}*'`]
        } else {
            searchValues = [`'${config.value}'`]
        }

        const searchValueLength = searchValues.length
        let searchValueIndex = -1

        while (++index < length) {
            let attributeSearch = ((index !== 0) ? ',' : '') + '('

            while (++searchValueIndex < searchValueLength) {
                attributeSearch = attributeSearch +
                    ((searchValueIndex !== 0) ? ';' : '') +
                    attributes[index] + '==' + searchValues[searchValueIndex]
            }

            searchQuery = searchQuery + attributeSearch + ')'
            searchValueIndex = -1
        }

        return searchQuery + suffix
    }

    return ''
}

function createPartialQuery(queryPart: string, config: IFetchSearchConfig): FetchQueryValue {
    if ((!queryPart || queryPart === '') && !config) {
        return null
    }

    const queryValues: FetchQueryValue = []
    const query: string = (queryPart && queryPart !== '')
        ? queryPart + createSearchQuery(';(', config)
        : createSearchQuery('', config, '')

    if (query !== '') {
        queryValues.push([
            'query',
            stripConditionals(query)
        ])
    }

    return queryValues.length !== 0 ? queryValues : null
}

function createRelationQueryBool(bool: boolean): [string, string] {
    if (bool) {
        return [
            'relations',
            'include[]'
        ]
    }

    return [
        'relations',
        'exclude[]'
    ]
}

function createRelationQueryList(list: string[], relationType: string): [string, string] {
    return [
        'relations',
        relationType + `[${list.join(',')}]`
    ]
}

function createRelationQuery(config: IFetchRelationConfig | boolean): FetchQueryValue {
    const queryValues = []

    if (typeof config === 'boolean') {
        queryValues.push(createRelationQueryBool(!config))
    } else {
        if (config !== null && config !== undefined) {
            if (typeof config.none === 'boolean') {
                queryValues.push(createRelationQueryBool(config.none))
            } else if (config.include !== undefined) {
                queryValues.push(createRelationQueryList(config.include, 'include'))
            } else if (config.exclude !== undefined) {
                queryValues.push(createRelationQueryList(config.exclude, 'exclude'))
            }
        } else {
            queryValues.push(createRelationQueryBool(true))
        }
    }

    return queryValues.length !== 0 ? queryValues : null
}

function createFetchQuery<T extends ad4mat.API.BASE.Data>(config: IFetchConfig<T>): object {
    const params = {}
    const queryParts = [
        createFetchPaginationQuery(config.pagination),
        createSortByQuery(config.sortBy),
        createPartialQuery(config.query, config.search),
        createRelationQuery(config.relations)
    ]

    for (const pairs of queryParts) {
        if (pairs === null) {
            continue
        }

        for (const pair of pairs) {
            params[pair[0]] = pair[1]
        }
    }

    return params
}

function fetchConfigIds(ids: string[]): string {
    if (!ids || ids.length === 0) {
        return null
    }

    return ids.join(',')
}

function createPayloadAttributes<T extends ad4mat.API.BASE.Data = ad4mat.API.BASE.Data>(
    data: T['attributes'] | EntityAttributes<T>
): T['attributes'] {
    const attributes = {}
    const keys = Object.keys(data)
    const length = keys.length
    let index = -1

    while (++index < length) {
        const key = keys[index]

        attributes[key] = data[key]
    }

    return attributes as T['attributes']
}

function createPayloadRelations<T extends ad4mat.API.BASE.Data = ad4mat.API.BASE.Data>(
    relations: T['relationships'] | EntityRelationships<T>
): PayloadRelationships<T> {
    const relationPayload: PayloadRelationships<T> = {}
    const keys = Object.keys(relations)
    const length = keys.length
    let index = -1

    while (++index < length) {
        const key = keys[index]
        const relation = relations[key]

        if (relation instanceof Array) {
            relationPayload[key] = {
                data: []
            }

            for (const entry of relation) {
                if (typeof entry === 'object') {
                    // tslint:disable: no-unsafe-any
                    relationPayload[key].data.push({
                        id: entry.id,
                        type: entry.type || key
                    })
                } else {
                    relationPayload[key].data.push({
                        id: entry,
                        type: key
                    })
                }
            }
        } else if (typeof relation === 'object' && relation !== null) {
            relationPayload[key] = {
                data: {
                    id: relation.id,
                    type: relation.type || key
                }
            }
            // tslint:enable: no-unsafe-any
        } else if (typeof relation === 'string') {
            relationPayload[key] = {
                data: {
                    id: relation,
                    type: key
                }
            }
        } else {
            relationPayload[key] = {
                data: null
            }
        }
    }

    return relationPayload
}

function createPayloadBody<T extends ad4mat.API.BASE.Data = ad4mat.API.BASE.Data>(
    resource: string,
    data: ICreatePayload<T> | IUpdatePayload<T>
): IPayload<T> {
    const payload: IPayload<T> = {
        data: {
            type: resource
        }
    }

    if ((data as IUpdatePayload<T>).id) {
        payload.data.id = (data as IUpdatePayload<T>).id
    }

    if (data.attributes) {
        payload.data.attributes = createPayloadAttributes<T>(data.attributes)
    }

    if (data.relationships) {
        payload.data.relationships = createPayloadRelations<T>(data.relationships)
    }

    return payload
}

async function createMultipleRequestForIds<T extends ad4mat.API.BASE.Data>(ids: string[], query: object, resource: string, token: IToken)
    : Promise<MultiSingleGenericResponse<T>> {

    const promises = []
    const res: MultiSingleGenericResponse<T> = {
        data: [],
        jsonapi: null,
        links: null,
        meta: null
    }

    while (ids.length !== 0) {
        const idList = ids.splice(0, MAX_ID_LENGTH)

        promises.push(
            (AXIOS.get(resource + (idList !== null ? '/' + idList.join(',') : ''), {
                headers: getDefaultHeader(token),
                params: query
            }))
        )
    }

    return Promise.all(promises)
        .then((responseList: Array<AxiosResponse<ad4mat.API.BASE.ResponseArray<T>>>) => {
            res.jsonapi = responseList[0].data.jsonapi
            res.links = responseList[0].data.links
            res.meta = responseList[0].data.meta

            for (const response of responseList) {
                res.data = res.data.concat(response.data.data)
            }

            return res
        })
        .catch()
}

export async function fetch<T extends ad4mat.API.BASE.Data = ad4mat.API.BASE.Data>(
    config: IFetchConfig<T>
): Promise<IFetchResponse<T>> {
    checkPermission(config.resource, PermissionType.Read)

    const ids = fetchConfigIds(config.ids)
    const idsSet = ids !== null
    const query = createFetchQuery(config)

    const response: MultiSingleGenericResponse<T> =
        (idsSet && ids.length > MAX_ID_LENGTH)
            ? await createMultipleRequestForIds(config.ids, query, config.resource, config.token)
            : (
                await AXIOS.get<ad4mat.API.BASE.ResponseArray<T>>(config.resource + (idsSet ? '/' + ids : ''), {
                    headers: getDefaultHeader(config.token),
                    params: query
                })
            ).data

    if ((response as IErrorResponse).errors) {
        handleDaaResponseError(response as IErrorResponse)
    }

    if (!response.data) {
        throw new Error('ERR_DAA_MALFORMED_RESPONSE')
    }

    return {
        data: (response.data instanceof Array) ? response.data : [response.data],
        pagination:
            (config.pagination !== undefined && response.meta !== undefined && response.meta !== null)
                ? {
                    currentPage: (typeof config.pagination.page === 'number') ? config.pagination.page : 1,
                    overallEntries: (response.meta as ad4mat.API.BASE.MetaPagination).overall_items,
                    totalPages: (response.meta as ad4mat.API.BASE.MetaPagination).total_pages
                } : null
    }
}

export async function create<T extends ad4mat.API.BASE.Data = ad4mat.API.BASE.Data>(
    resource: T['type'],
    payload: ICreatePayload<T>
): Promise<T> {
    return (await daaCreate<T>(resource, createPayloadBody<T>(resource, payload))).data.data
}

export async function update<T extends ad4mat.API.BASE.Data = ad4mat.API.BASE.Data>(
    resource: T['type'],
    payload: IUpdatePayload<T>
): Promise<T> {
    return (await daaUpdate<T>(resource, payload.id, createPayloadBody<T>(resource, payload))).data.data
}

export async function destroy<T extends ad4mat.API.BASE.Data = ad4mat.API.BASE.Data>(
    resource: T['type'],
    id: T['id']
): Promise<T> {
    return (await daaDestroy<T>(resource, id)).data.data
}

export async function bulkCreate<T extends ad4mat.API.BASE.Data = ad4mat.API.BASE.Data>(
    resource: T['type'],
    payloadList: Array<ICreatePayload<T>>
): Promise<T[]> {
    return Promise.all(payloadList.map((payload: ICreatePayload<T>) => create<T>(resource, payload)))
}

export function mergeQueryStrings(operator: ';' | ',', ...args: string[]): string {
    let mergedQuery = ''

    for (const query of args) {
        if (typeof query === 'string') {
            mergedQuery = mergedQuery + stripConditionals(query) + operator
        }
    }

    return stripConditionals(mergedQuery)
}

export function payloadInterpreter(): PayloadInterpreter {
    return new PayloadInterpreter(
        (payload: IDaaCreatePayload) => {
            return create(payload.type, payload)
        },
        (payload: IDaaUpdatePayload) => {
            return update(payload.type, payload)
        },
        // tslint:disable-next-line: space-before-function-paren
        async (type: string, id: string) => {
            await destroy(type, id)
        }
    )
}
