import * as React from 'react'

import { ButtonClassType, ButtonMethodType, ButtonSize } from 'components/button/constants'

interface IProps extends React.Props<object> {
    classType?: ButtonClassType
    methodType?: ButtonMethodType
    className?: string
    size?: ButtonSize
    clickHandler?: React.MouseEventHandler<HTMLButtonElement>
    isPreventDefault?: boolean
    disabled?: boolean
}

export default class Button extends React.Component<IProps> {
    public constructor(props: IProps) {
        super(props)

        this.clickHandler = this.clickHandler.bind(this)
    }

    public shouldComponentUpdate(nextProps: IProps): boolean {
        return (
            this.props.children !== nextProps.children ||
            this.props.classType !== nextProps.classType ||
            this.props.methodType !== nextProps.methodType ||
            this.props.className !== nextProps.className ||
            this.props.size !== nextProps.size ||
            this.props.clickHandler !== nextProps.clickHandler ||
            this.props.isPreventDefault !== nextProps.isPreventDefault ||
            this.props.disabled !== nextProps.disabled
        )
    }

    public render(): React.ReactNode {
        const size = this.props.size || ''
        const methodType = this.props.methodType || 'button'
        let className = 'uk-button ' + size

        if (this.props.classType) {
            className += ' ' + this.props.classType
        }

        if (this.props.className) {
            className += ' ' + this.props.className
        }

        return (
            <button
                className={className}
                onClick={this.clickHandler}
                type={methodType}
                disabled={this.props.disabled || false}
                key={size + methodType + className}
            >
                {this.props.children}
            </button>
        )
    }

    private clickHandler(ev: React.MouseEvent<HTMLButtonElement>): void {
        if (this.props.isPreventDefault !== false) {
            ev.preventDefault()
        }

        if (this.props.clickHandler) {
            this.props.clickHandler(ev)
        }
    }
}
