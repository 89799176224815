import version from '../version.txt'
import { isProduction } from './env'

export function getVersion(): string {
    const match = (
        (isProduction)
            ? /ONE\s+([0-9]+\.[0-9]+\.[0-9]+)/i
            : /\#([0-9]+)/i
    ).exec(version)

    if (match) {
        return match[1]
    }

    return 'development'
}

export function getFullVersion(): string {
    return version
}
