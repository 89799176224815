import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import FontAwesomeIcon from '../fa-icon'
import { IIcon } from '../fa-icon/typings'

export interface ITranslateValues {
    [key: string]: JSX.Element
}

interface IProps extends React.Props<object> {
    message: string
    isRequired?: boolean
    tagName?: string
    icon?: IIcon
    className?: string
    values?: ITranslateValues
    onClick?(ev: React.MouseEvent<HTMLElement>): void
}

export default function Translate(props: IProps): React.ReactElement<object> | null {
    if (!props.message || props.message.length === 0) {
        return null
    }

    return React.createElement(
        props.tagName || 'span',
        {
            className: props.className,
            // tslint:disable-next-line: no-unbound-method
            onClick: props.onClick
        },
        (
            (props.icon) && (
                <FontAwesomeIcon icon={props.icon.name} margin={props.icon.margin} />
            )
        ),
        (
            <React.Fragment>
                <FormattedMessage id={props.message} values={props.values} />
                {
                    (props.isRequired) && (
                        <span>*</span>
                    )
                }
            </ React.Fragment>
        )
    )
}
