import { SET_LANGUAGE, SET_LANGUAGES } from './constants'
import { IAction, IState } from './typings/redux-state'

export default function languageSwitchReducer(
    state: IState,
    action: IAction
): IState {
    if (!state) {
        return {
            language: null,
            languages: []
        }
    }

    switch (action.type) {
        case SET_LANGUAGE:
            return {
                language: action.language,
                languages: state.languages
            }
        case SET_LANGUAGES:
            return {
                language: state.language,
                languages: action.languages
            }
    }

    return state
}
