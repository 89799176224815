import { revokeToken } from 'services/oauth/api'
import { CREDENTIALS, SessionEventTypes } from 'services/session/constants'
import { destroyState, getToken, getTokenTypeList, removeToken, session } from 'services/user-session'
import { login } from '../../login/action'

export default function logout(isEvent: boolean = false): Promise<void> {
    return login(false, isEvent)
        .then(() => invalidateTokens(isEvent))
        .then(destroyState)
}

function invalidateTokens(isEvent: boolean): Promise<void> {
    const availableTokens = getTokenTypeList()
    const promiseList = []

    for (const tokenType of availableTokens) {
        const token = getToken(tokenType)

        if (token !== null) {
            removeToken(tokenType, isEvent)

            if (!isEvent) {
                promiseList.push(
                    revokeToken({
                        ...CREDENTIALS.get(tokenType),
                        token: token.token
                    })
                )
            }
        }
    }

    if (isEvent) {
        return Promise.resolve()
    }

    return Promise.all(promiseList)
        .then(() => session.notify(SessionEventTypes.LOGOUT))
}
