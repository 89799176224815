import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import saveBase64ToFile, { DownloadFileType } from 'lib/base64-file-download'
import { errorMessage } from 'lib/notification-api'
import { BASE_URL, CloudFunction, cloudFunctionMap } from './constants'

interface IRequestConfig extends Object {
    authToken?: string
    responseType?: string
}

const baseConfig: AxiosRequestConfig = Object.freeze({
    headers: {
        'content-type': 'application/json'
    }
})

function getRequestConfig(requestConfig: IRequestConfig): AxiosRequestConfig {
    const config = { ...baseConfig }

    if (requestConfig.authToken) {
        config.headers = {
            ...config.headers,
            authorization: requestConfig.authToken
        }
    }

    if (requestConfig.responseType) {
        config.headers = {
            ...config.headers,
            responseType: requestConfig.responseType
        }
    }

    return config
}

export async function saveXLSX(
    data: string,
    name: string,
    isCompressed: boolean = false,
    isStrict: boolean = true,
    useXlsProcessing: boolean = false
) {
    try {
        const response = await cloudFunctionPost<string>(
            CloudFunction.csvToExcel,
            { data, compression: isCompressed, disableStrict: !isStrict, useXlsProcessing },
            { responseType: 'text/plain' }
        )

        saveBase64ToFile(response.data, name, DownloadFileType.XLSX)
    } catch (err) {
        errorMessage(err as Error)
        // throw the error again so the caller knows something went wrong
        throw err
    }
}

export async function cloudFunctionGet<T = unknown>(
    cloudFn: CloudFunction,
    config: AxiosRequestConfig
): Promise<AxiosResponse<T>> {
    return axios.get(
        BASE_URL + '/' + cloudFunctionMap[cloudFn],
        config
    )
}

export async function cloudFunctionPost<T = unknown>(
    cloudFn: CloudFunction,
    body: object,
    requestConfig?: IRequestConfig
): Promise<AxiosResponse<T>> {
    return axios.post(
        BASE_URL + '/' + cloudFunctionMap[cloudFn],
        body,
        (requestConfig) ? getRequestConfig(requestConfig) : baseConfig
    )
}
