import * as deepEqual from 'fast-deep-equal'

import { user } from 'lib/store'
import { IToken, PermissionMap } from 'services/oauth/typings'
import { EventHandler } from 'services/session/typings'
import { loadUserConfig, saveUserConfig } from 'services/storage-access/api'
import { IState, IUserData, IUserDataUpdate } from 'services/user-session/typings'
import { addItem, getItem, hasItem } from '../../lib/storage'
import { validateToken } from '../oauth/api'
import { SessionEventTypes } from '../session/constants'
import SessionInstance from '../session/index'
import {
    DESTROY_STATE,
    REMOVE_PREFERENCE,
    REMOVE_TOKEN,
    SET_PERMISSIONS,
    SET_PREFERENCE,
    SET_TOKEN,
    SET_USER,
    STATE_STORAGE_KEY,
    StateLoadType,
    TokenType
} from './constants'

const session = new SessionInstance(setToken, removeToken)
let bootstrapComplete = false
let prevTokenState: Map<TokenType, IToken> = null
let prevUserConfigState: object = null

function shouldUpdate(type: TokenType, token: IToken): boolean {
    const currentToken = getToken(type)

    if (!currentToken) {
        return true
    }

    return token !== undefined &&
        token !== null &&
        token.token !== currentToken.token
}

function handleSessionTokenEvents(tokenType: TokenType): EventHandler {
    return (data: string) => {
        if (data !== 'null') {
            setToken(tokenType, JSON.parse(data), true)
            session.checkInterval(tokenType)
        } else {
            removeToken(tokenType, true)
        }
    }
}

function handleSessionUserEvent(data: string): void {
    setUserData(JSON.parse(data), true)
}

function handlePermissionUpdateEvent(data: string): void {
    setPermissions(new Map(JSON.parse(data)), true)
}

function mapTokenTypeToEventType(tokenType: TokenType): SessionEventTypes {
    switch (tokenType) {
        case TokenType.AdminAuth:
            return SessionEventTypes.ADMIN_TOKEN
        case TokenType.DataAccessApi:
            return SessionEventTypes.DAA_TOKEN
        case TokenType.UserAuth:
            return SessionEventTypes.USER_TOKEN
        case TokenType.StorageAccessApi:
            return SessionEventTypes.SAA_TOKEN
        default:
            return null
    }
}

function handleRefreshLockRelease(): void {
    if (session.isLockingRefresh()) {
        const tokenTypeList = getTokenTypeList()

        for (const tokenType of tokenTypeList) {
            session.checkInterval(tokenType)
        }
    }
}

async function saveTokenState(): Promise<boolean> {
    const tokenMap = (user.getState() as IState).user.tokens

    if (tokenMap === prevTokenState) {
        return true
    }

    prevTokenState = tokenMap

    const tokens = tokenMap.entries()
    const tokenList = []

    if (!bootstrapComplete) {
        return false
    }

    for (const token of tokens) {
        tokenList.push(token)
    }

    return addItem(STATE_STORAGE_KEY, JSON.stringify(tokenList))
}

async function savePreferenceState(): Promise<boolean> {
    if (!bootstrapComplete || !prevUserConfigState) {
        return false
    }

    const token = getToken(TokenType.StorageAccessApi)

    if (!token) {
        return false
    }

    const userConfig = (user.getState() as IState).user.preferences
    const isEqual = deepEqual(userConfig, prevUserConfigState)

    prevUserConfigState = userConfig

    if (isEqual) {
        return true
    }

    return (await saveUserConfig(token, userConfig)).status === 200
}

async function loadTokenState(): Promise<boolean> {
    if (!await hasItem(STATE_STORAGE_KEY)) {
        return false
    }

    const keepLoggedIn = getPreference('kli')
    const tokens = JSON.parse(await getItem(STATE_STORAGE_KEY)) as Array<[TokenType, IToken]>
    let validTokens = 0

    for (const entry of tokens) {
        if (validateToken(entry[1])) {
            setToken(entry[0], entry[1], true)
            validTokens++
        } else if (keepLoggedIn === true && await session.requestTokenRefresh(entry[0], entry[1])) {
            validTokens++
        }
    }

    return validTokens !== 0
}

export async function loadPreferenceState(): Promise<boolean> {
    const token = getToken(TokenType.StorageAccessApi)

    if (!token) {
        return false
    }

    const response = await loadUserConfig(token)
    const preferences = (response.status === 200) ? response.data : null

    if (!preferences) {
        return false
    }

    const preferenceKeys = Object.keys(preferences)

    prevUserConfigState = preferences

    for (const key of preferenceKeys) {
        setPreference(key, preferences[key])
    }

    return true
}

async function saveState(): Promise<boolean> {
    return (
        await saveTokenState() && savePreferenceState()
    )
}

export async function loadState(): Promise<StateLoadType> {
    let status = StateLoadType.None

    try {
        if (await loadTokenState()) {
            status = StateLoadType.Token
        }
    } catch (err) {
        console.error(err)
    }

    try {
        if (await loadPreferenceState()) {
            status = StateLoadType.UserPreference
        }
    } catch (err) {
        console.error(err)
    }

    bootstrapComplete = true

    return status
}

export function setToken(type: TokenType, token: IToken, isEvent: boolean = false): void {
    if (shouldUpdate(type, token)) {
        user.dispatch({
            token,
            tokenType: type,
            type: SET_TOKEN
        })

        session.checkInterval(type)

        if (!isEvent) {
            const eventType = mapTokenTypeToEventType(type)

            if (eventType !== null) {
                session.notify(eventType, JSON.stringify(token))
            }
        }
    }
}

export function getToken(type: TokenType): IToken | null {
    const tokens = user.getState().user.tokens

    if (!tokens.has(type)) {
        return null
    }

    return tokens.get(type)
}

export function getTokenTypeList(): TokenType[] {
    const tokenList: TokenType[] = []
    const iterator = user.getState().user.tokens
        .keys()

    for (const tokenType of iterator) {
        tokenList.push(tokenType)
    }

    return tokenList
}

export function removeToken(type: TokenType, isEvent: boolean = false): void {
    user.dispatch({
        tokenType: type,
        type: REMOVE_TOKEN
    })

    session.removeCheckInterval(type)

    if (!isEvent) {
        const eventType = mapTokenTypeToEventType(type)

        if (eventType !== null) {
            session.notify(eventType, 'null')
        }
    }
}

export function setPreference(name: string, value: any): void {
    user.dispatch({
        preference: { name, value },
        type: SET_PREFERENCE
    })
}

export function getPreference<T = any>(name: string): T | null {
    const preferences = user.getState().user.preferences

    if (!preferences.hasOwnProperty(name)) {
        return null
    }

    return preferences[name] as T
}

export function removePreference(name: string): void {
    user.dispatch({
        preference: name,
        type: REMOVE_PREFERENCE
    })
}

export function setUserData(userData: IUserDataUpdate | IUserData, isEvent: boolean = false): void {
    const state: IState = user.getState()

    user.dispatch({
        type: SET_USER,
        userData
    })

    if (!isEvent) {
        session.notify(SessionEventTypes.USER_DATA_UPDATE, JSON.stringify({ ...state.user.data, ...userData }))
    }
}

export function setPermissions(permissions: PermissionMap, isEvent: boolean = false): void {
    user.dispatch({
        permissions,
        type: SET_PERMISSIONS
    })

    if (!isEvent) {
        const entries = permissions.entries()
        const permissionList = []
        let current = entries.next()

        while (!current.done) {
            permissionList.push(current.value)
            current = entries.next()
        }

        session.notify(SessionEventTypes.PERMISSION_UPDATE, JSON.stringify(permissionList))
    }
}

export function destroyState() {
    user.dispatch({
        type: DESTROY_STATE
    })
}

export { session }

session.addHandler(SessionEventTypes.ADMIN_TOKEN, handleSessionTokenEvents(TokenType.AdminAuth))
session.addHandler(SessionEventTypes.DAA_TOKEN, handleSessionTokenEvents(TokenType.DataAccessApi))
session.addHandler(SessionEventTypes.USER_TOKEN, handleSessionTokenEvents(TokenType.UserAuth))
session.addHandler(SessionEventTypes.SAA_TOKEN, handleSessionTokenEvents(TokenType.StorageAccessApi))
session.addHandler(SessionEventTypes.REFRESH_LOCK_RELEASE, handleRefreshLockRelease)
session.addHandler(SessionEventTypes.PERMISSION_UPDATE, handlePermissionUpdateEvent)
session.addHandler(SessionEventTypes.USER_DATA_UPDATE, handleSessionUserEvent)
user.subscribe(saveState)
