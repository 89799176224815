import { saveAs } from 'file-saver'

export const enum DownloadFileType {
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export function base64ToUint8Array(data: string): Uint8Array {
    const encodedBytes = atob(data)
    const bytes = new Array(encodedBytes.length)

    for (let i = 0; i < encodedBytes.length; i++) {
        bytes[i] = encodedBytes.charCodeAt(i)
    }

    return new Uint8Array(bytes)
}

export default function saveBase64ToFile(data: string, fileName: string, fileType: DownloadFileType) {
    saveAs(new Blob([base64ToUint8Array(data)], { type: fileType }), fileName)
}
