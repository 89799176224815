import nextTick from '@ad4mat/javascript-utilities/dist/esm/lib/async/async'

import { user } from 'lib/store'
import { IToken } from 'services/oauth/typings'
import { EventHandler, EventHandlerMap, ICheckEntry, RemoveHandler, RemoveTokenFn, SetTokenFn } from 'services/session/typings'
import { TokenType } from 'services/user-session/constants'
import { IState } from 'services/user-session/typings'
import base36Id from '../../lib/base36-id'
import { refreshToken as refreshTokenRequest, validateToken } from '../oauth/api'
import {
    CREDENTIALS,
    REFRESH_LOCK_KEY,
    REFRESH_PERCENTAGE,
    SESSION_KEY,
    SessionEventTypes
} from './constants'
import {
    addToInstanceList,
    getInstanceList,
    removeFromInstanceList,
    setInstanceList
} from './instance'

class Session {
    public readonly instanceId: string = this.generateId()
    private timers: Map<TokenType, any> = new Map()
    private eventHandler: EventHandlerMap = new Map()
    private refreshList: TokenType[] = []
    private lockingRefresh: boolean = false
    private setToken: SetTokenFn
    private removeToken: RemoveTokenFn

    public constructor(setToken: SetTokenFn, removeToken: RemoveTokenFn) {
        this.setup()
        this.setToken = setToken
        this.removeToken = removeToken
        this.checkTokenRefresh = this.checkTokenRefresh.bind(this)
    }

    private static calculateTimeout(token: IToken): number {
        const time = Math.ceil(((token.expires - Date.now()) * REFRESH_PERCENTAGE) / 100)

        return (time > 0) ? time : 0
    }

    private static addSessionEvent(key: string, value: string): void {
        try {
            localStorage.setItem(key, value)
        } catch (err) {
            // localStorage insert failed
        }
    }

    private static removeSessionEvent(key: string): void {
        try {
            localStorage.removeItem(key)
        } catch (err) {
            // localStorage remove failed
        }
    }

    private static getRefreshLock(): string {
        try {
            return localStorage.getItem(REFRESH_LOCK_KEY)
        } catch (err) {
            return null
        }
    }

    private static setRefreshLock(instanceId: string): boolean {
        try {
            localStorage.setItem(REFRESH_LOCK_KEY, instanceId)

            return true
        } catch (err) {
            return false
        }
    }

    private static removeRefreshLock(): void {
        try {
            localStorage.removeItem(REFRESH_LOCK_KEY)
        } catch (err) {
            // localStorage remove failed
        }
    }

    public addHandler(type: SessionEventTypes, handler: EventHandler): RemoveHandler {
        if (this.eventHandler.has(type)) {
            this.eventHandler
                .get(type)
                .push(handler)
        } else {
            this.eventHandler.set(type, [handler])
        }

        return () => {
            const eventHandler = this.eventHandler.get(type)
            const index = eventHandler.indexOf(handler)

            if (index !== -1) {
                eventHandler.splice(index, 1)
            }
        }
    }

    public notify(type: SessionEventTypes, data: string = ''): void {
        Session.addSessionEvent(SESSION_KEY, type + ':' + this.instanceId + ':' + data)

        nextTick(Session.removeSessionEvent, undefined, SESSION_KEY)
    }

    public checkInterval(type: TokenType): void {
        if (!this.lockingRefresh) {
            this.removeCheckInterval(type)

            return
        }

        const tokens = (user.getState() as IState).user.tokens

        if (!tokens.has(type)) {
            return
        }

        const timerId = this.timers.get(type)

        if (timerId !== undefined) {
            clearTimeout(timerId)
        }

        this.timers.set(type, setTimeout(
            this.checkTokenRefresh,
            Session.calculateTimeout(tokens.get(type)),
            type
        ))
    }

    public removeCheckInterval(type: TokenType): void {
        if (this.timers.has(type)) {
            const id = this.timers.get(type)

            this.timers.delete(type)
            clearTimeout(id)
        }
    }

    public isLockingRefresh(): boolean {
        return this.lockingRefresh
    }

    public async requestTokenRefresh(type: TokenType, currentToken: IToken): Promise<boolean> {
        if (!this.lockingRefresh) {
            return false
        }

        if (
            !this.addToRefresh(type) ||
            !CREDENTIALS.has(type)
        ) {
            this.removeFromRefresh(type)

            return false
        }

        const tokenStatus = await this.tryTokenRefresh(type, currentToken)

        this.removeFromRefresh(type)

        if (!tokenStatus) {
            if (!validateToken(currentToken)) {
                this.removeToken(type)
            }

            return false
        }

        return true
    }

    private async tryTokenRefresh(type: TokenType, currentToken: IToken): Promise<boolean> {
        try {
            const token = await this.refreshToken(type, currentToken)

            this.setToken(type, token)

            return true
        } catch (err) {
            console.error(err)

            return false
        }
    }

    private async checkTokenRefresh(type: TokenType): Promise<boolean> {
        const state = user.getState()

        if (!state.user.tokens.has(type)) {
            return false
        }

        if (!await this.requestTokenRefresh(type, state.user.tokens.get(type))) {
            // tslint:disable-next-line:newline-per-chained-call
            if (user.getState().user.tokens.has(type)) {
                this.checkInterval(type)

                return true
            } else {
                return false
            }
        }

        return true
    }

    private destroy(): void {
        removeFromInstanceList(this.instanceId)
        this.releaseRefreshLock()
    }

    private setup(): void {
        this.registerInstance()

        this.lockingRefresh = this.addRefreshLock()

        window.addEventListener('storage', (ev: StorageEvent) => this.handleStorageEvent(ev))
        window.addEventListener('beforeunload', () => this.destroy())

        this.addHandler(SessionEventTypes.PING, (data: string) => {
            if (this.instanceId === data) {
                this.notify(SessionEventTypes.PONG, this.instanceId)
            }
        })

        this.addHandler(SessionEventTypes.REFRESH_LOCK_RELEASE, () => {
            this.lockingRefresh = this.addRefreshLock()
        })
    }

    private registerInstance(): void {
        addToInstanceList(this.instanceId)
    }

    private shouldLock(): boolean {
        if (this.lockingRefresh) {
            return false
        }

        try {
            return getInstanceList()[0] === this.instanceId
        } catch (err) {
            return false
        }
    }

    private async checkInstances(lock: string = Session.getRefreshLock()): Promise<void> {
        const healthyInstances = await this.instanceCheck()

        setInstanceList(healthyInstances)

        if (lock === Session.getRefreshLock() && healthyInstances.indexOf(lock) === -1) {
            if (healthyInstances[0] === this.instanceId) {
                this.lockingRefresh = Session.setRefreshLock(this.instanceId)
            } else {
                this.notify(SessionEventTypes.REFRESH_LOCK_RELEASE, lock)
            }
        }
    }

    private addRefreshLock(): boolean {
        if (this.shouldLock()) {
            const lock = Session.getRefreshLock()

            if (lock !== null) {
                const instanceList = getInstanceList()

                if (instanceList.indexOf(lock) !== -1) {
                    this.checkInstances(lock)

                    return false
                }
            }

            return Session.setRefreshLock(this.instanceId)
        }

        this.checkInstances()

        return false
    }

    private releaseRefreshLock(): void {
        if (this.lockingRefresh) {
            const lock = Session.getRefreshLock()

            if (lock !== null && lock === this.instanceId) {
                Session.removeRefreshLock()
            }

            this.notify(SessionEventTypes.REFRESH_LOCK_RELEASE, this.instanceId)
        }
    }

    private generateId(): string {
        const instanceList = getInstanceList()
        let id = base36Id()

        while (instanceList.indexOf(id) !== -1) {
            id = base36Id()
        }

        return id
    }

    private callListener(type: SessionEventTypes, data?: string): void {
        if (this.eventHandler.has(type)) {
            const eventHandler = this.eventHandler.get(type)

            if (eventHandler.length !== 0) {
                for (const handler of eventHandler) {
                    handler(data)
                }
            }
        }
    }

    private handleStorageEvent(ev: StorageEvent) {
        if (ev.key === SESSION_KEY && ev.newValue !== null) {
            const data = ev.newValue
            const typeIndex = data.indexOf(':')
            const instanceIndex = data.indexOf(':', typeIndex + 1)
            const type = Number(data.slice(0, typeIndex))
            const instance = data.slice(typeIndex + 1, instanceIndex)

            if (instance === this.instanceId) {
                return
            }

            if (!isNaN(type)) {
                this.callListener(type, data.slice(instanceIndex + 1))
            }
        }
    }

    private refreshToken(type: TokenType, currentToken: IToken): Promise<IToken> {
        const credentials = CREDENTIALS.get(type)

        return refreshTokenRequest({
            client_id: credentials.client_id,
            client_secret: credentials.client_secret,
            refresh_token: currentToken.refreshToken
        })
    }

    private addToRefresh(type: TokenType): boolean {
        if (this.refreshList.includes(type)) {
            return false
        }

        this.refreshList.push(type)

        return true
    }

    private removeFromRefresh(type: TokenType): boolean {
        const index = this.refreshList.indexOf(type)

        if (index === -1) {
            return false
        }

        this.refreshList.splice(index, 1)

        return true
    }

    private async instanceCheck(): Promise<string[]> {
        const instanceList = getInstanceList()

        if (instanceList.length === 1 && instanceList[0] === this.instanceId) {
            return [this.instanceId]
        }

        const promiseList = []

        for (const instance of instanceList) {
            if (instance !== this.instanceId) {
                promiseList.push(
                    new Promise((resolveInstance: ResolveRejectFn<ICheckEntry>) => {
                        let removeHandler: () => void

                        const timer = setTimeout(
                            () => {
                                resolveInstance([instance, false])
                                removeHandler()
                            },
                            1000
                        )

                        removeHandler = this.addHandler(
                            SessionEventTypes.PONG,
                            (data: string) => {
                                if (data === instance) {
                                    clearTimeout(timer)
                                    removeHandler()
                                    resolveInstance([instance, true])
                                }
                            }
                        )

                        this.notify(SessionEventTypes.PING, instance)
                    })
                )
            }
        }

        const results: ICheckEntry[] = await Promise.all(promiseList)
        const healthyInstances: string[] = [this.instanceId]

        for (const result of results) {
            if (result[1]) {
                healthyInstances.push(result[0])
            }
        }

        return healthyInstances
    }
}

export default Session
