export const BASE_URL = 'https://europe-west1-ad4mat-one-prod.cloudfunctions.net'

export const enum CloudFunction {
    csvToExcel,
    downloadInvoice,
    reportError
}

export const cloudFunctionMap = Object.freeze({
    [CloudFunction.csvToExcel]: 'csv_to_excel',
    [CloudFunction.downloadInvoice]: 'invoice_link_generator',
    [CloudFunction.reportError]: 'report_error'
})
