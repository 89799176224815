import * as React from 'react'
import { connect } from 'react-redux'
import { HashRouter as Router } from 'react-router-dom'

import ResetScroll from 'components/reset-scroll/index'
import IDataState from 'typings/data-state'
import ModalRoot from '../modal-root'
import { State } from '../modal/typings'
import ActiveRoute from './components/active-route'
import { userConfirm, NotificationType } from 'lib/notification-api'

interface IProps extends Object {
    modal: State
}

class Routes extends React.Component<IProps> {
    private static handleNavigation(message: string, callback: (status: boolean) => void): void {
        userConfirm(
            'CONFIRM_NAVIGATION',
            message,
            'CONTINUE',
            'ABORT',
            NotificationType.INFO
        )
            .then(callback)
            .catch((err: Error) => {
                console.error(err)
                callback(false)
            })
    }

    public shouldComponentUpdate(nextProps: IProps): boolean {
        return (
            this.props.modal !== nextProps.modal
        )
    }

    public render() {
        return (
            <Router
                hashType='hashbang'
                basename='/'
                getUserConfirmation={Routes.handleNavigation}
            >
                <main>
                    <ResetScroll />
                    {
                        (this.props.modal) && (
                            <ModalRoot />
                        )
                    }
                    <ActiveRoute />
                </main>
            </ Router>
        )
    }
}

function mapStateToProps(state: IDataState): IProps {
    return {
        modal: state.components.modal
    }
}

export default connect(mapStateToProps)(Routes)
