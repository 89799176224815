import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'

class ResetScroll extends React.Component<RouteComponentProps<any>> {
    public componentDidUpdate(prevProps: RouteComponentProps<any>) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0)
        }
    }

    public render(): React.ReactNode {
        return null
    }
}

export default withRouter(ResetScroll)
