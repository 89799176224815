import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import * as qs from 'qs'

import { ad4mat } from 'ad4mat-daa-model/daa_model'
import PermissionError from 'lib/permission-error'
import { getVersion } from 'lib/version'
import { IErrorResponse, IPayload, MultipleEntities, SingleEntity } from 'services/daa/typings'
import { IToken } from 'services/oauth/typings'
import { PermissionType } from '../oauth/constants'
import { getToken } from '../user-session'
import { TokenType } from '../user-session/constants'
import { hasDaaPermission } from '../user/index'
import { BASE_URL, CONTENT_TYPE } from './constants'
import DAAError from './daa-error'

export const AXIOS = axios.create({
    baseURL: BASE_URL,
    headers: {
        'X-Requested-With': 'ONE-UI ' + getVersion()
    },
    paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: 'brackets', encodeValuesOnly: true }),
    timeout: 30000
})

export function getDefaultHeader(tokenOverride?: IToken): object {
    const token = tokenOverride
        ? tokenOverride
        : getToken(TokenType.DataAccessApi)

    if (token === null) {
        throw new Error('ERR_DAA_NO_AUTH')
    }

    return {
        'Authorization': token.type + ' ' + token.token,
        'Content-Type': CONTENT_TYPE
    }
}

export function checkPermission(resource: string, type: PermissionType): void {
    if (!hasDaaPermission(resource, type)) {
        throw new PermissionError(resource, type)
    }
}

export function handleDaaResponseError(response: IErrorResponse): void {
    if (response.errors instanceof Array && response.errors.length !== 0) {
        throw new DAAError(response.errors)
    }

    throw new Error('ERR_DAA_MALFORMED_ERROR')
}

function getRelationParam(payload: IPayload): string {
    const relations = (payload.data.relationships) ? Object.keys(payload.data.relationships) : []

    return 'relations=include[' + relations.join(',') + ']'
}

export async function create<T extends ad4mat.API.BASE.Data = ad4mat.API.BASE.Data>(
    resource: string,
    payload: IPayload<T>
): Promise<AxiosResponse<SingleEntity<T>>> {
    checkPermission(resource, PermissionType.Write)

    const response = await AXIOS.post(resource, payload, {
        headers: getDefaultHeader()
    })

    if ((response.data as IErrorResponse).errors) {
        handleDaaResponseError(response.data)
    }

    return response
}

export async function update<T extends ad4mat.API.BASE.Data = ad4mat.API.BASE.Data>(
    resource: string,
    id: string,
    payload: IPayload<T>
): Promise<AxiosResponse<SingleEntity<T>>> {
    checkPermission(resource, PermissionType.Write)

    const response = await AXIOS.patch(
        resource + '/' + id + '?' + getRelationParam(payload),
        payload,
        { headers: getDefaultHeader() }
    )

    if ((response.data as IErrorResponse).errors) {
        handleDaaResponseError(response.data)
    }

    return response
}

export async function destroy<T extends ad4mat.API.BASE.Data = ad4mat.API.BASE.Data>(
    resource: string,
    id: string
): Promise<AxiosResponse<SingleEntity<T>>> {
    checkPermission(resource, PermissionType.Delete)

    const response = await AXIOS.delete(resource + '/' + id, {
        headers: getDefaultHeader()
    })

    if ((response.data as IErrorResponse).errors) {
        handleDaaResponseError(response.data)
    }

    return response
}

export async function fetch<T extends ad4mat.API.BASE.Data = ad4mat.API.BASE.Data>(
    resource: string,
    id: string,
    params: object = null
): Promise<AxiosResponse<SingleEntity<T>>> {
    checkPermission(resource, PermissionType.Read)

    const response = await AXIOS.get(resource + '/' + id, {
        headers: getDefaultHeader(),
        params
    })

    if ((response.data as IErrorResponse).errors) {
        handleDaaResponseError(response.data)
    }

    return response
}

export async function fetchAll<T extends ad4mat.API.BASE.Data = ad4mat.API.BASE.Data>(
    resource: string,
    params: object = null,
    timeout?: number
): Promise<AxiosResponse<MultipleEntities<T>>> {
    checkPermission(resource, PermissionType.Read)

    const config: AxiosRequestConfig = {
        headers: getDefaultHeader(),
        params
    }

    if (typeof timeout === 'number') {
        config.timeout = timeout
    }

    const response = await AXIOS.get(resource, config)

    if ((response.data as IErrorResponse).errors) {
        handleDaaResponseError(response.data)
    }

    return response
}
