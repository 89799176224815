import * as React from 'react'

import '@fortawesome/fontawesome-free/css/all.css'

interface IProps extends React.Props<object> {
    icon: string
    margin?: string
    size?: number
    className?: string
}

export default function FontAwesomeIcon(props: IProps): React.ReactElement<any> {
    const size = (props.size) ? `fa-${props.size}x` : ''
    let margin = ''

    switch (props.margin) {
        case 'left':
            margin = 'uk-margin-small-left'
            break
        case 'right':
            margin = 'uk-margin-small-right'
            break
    }

    const className = `fas fa-${props.icon} ${size} ${margin} ${(props.className) ? props.className : ''}`

    return (
        <i className={className} aria-hidden={true} />
    )
}
