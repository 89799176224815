interface IClassName {
    [className: string]: boolean
}

export default function classNameHelper(classNameObject: IClassName): string {
    const classNames = Object.keys(classNameObject)
    const length = classNames.length
    let output = ''
    let index = -1

    while (++index < length) {
        const className = classNames[index]

        if (classNameObject[className]) {
            output = output + (
                (index === 0)
                    ? className
                    : ' ' + className
            )
        }
    }

    return output.length === 0 ? null : output.trim()
}
