import { Language } from 'services/storage-access/typings'
import { getPreference, setPreference } from 'services/user-session/index'

export const supportedLanguages: Language[] = ['en-GB', 'en-US', 'en']

export function getSupportedLanguage(): Language {
    const userLanguages = (Array.isArray(navigator.languages))
        ? navigator.languages as string[]
        : [navigator.language]

    const languageList = userLanguages

    for (const lang of languageList) {
        if (supportedLanguages.includes(lang as Language)) {
            return lang as Language
        }
    }

    return supportedLanguages[0]
}

export function setupUserLocale(lang?: Language, isEvent: boolean = false): string {
    const userLanguage = (!lang) ? getSupportedLanguage() : lang

    if (!isEvent || getPreference('lang') === null) {
        setPreference('lang', userLanguage)
    }

    return userLanguage
}
