import 'uikit/dist/css/uikit.min.css'
import 'uikit/dist/js/uikit'
import './assets/stylesheet.sass'

import initErrorReporting from 'lib/error-report'
import { errorMessage } from 'lib/notification-api'
import registerServiceWorker from 'lib/service-worker'
import 'lib/theme-switcher'
import { isProduction } from './lib/env'
import render from './render'

const element = document.getElementById('app-container')

initErrorReporting()
render(element)

if (isProduction && 'serviceWorker' in navigator) {
    registerServiceWorker()
        .catch(errorMessage)
}
