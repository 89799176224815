import * as React from 'react'
import { connect } from 'react-redux'

import classNameHelper from 'lib/class-name'
import IDataState from 'typings/data-state'
import Button from '../button'
import { ButtonClassType } from '../button/constants'
import FontAwesomeIcon from '../fa-icon'
import { ModalHandler } from '../modal/typings'
import Translate from '../translate'

interface IProps extends React.Props<HTMLElement> {
    name: string
    cancelFn: ModalHandler
    body: React.ReactNode
    successFn?: ModalHandler
    size?: {
        width: number
        height: number
    }
}

class ModalRoot extends React.Component<IProps> {
    private modalDialog: HTMLElement

    public constructor(props: IProps, context: object) {
        super(props, context)

        this.handleOutsideClick = this.handleOutsideClick.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleConfirm = this.handleConfirm.bind(this)
        this.getElementRef = this.getElementRef.bind(this)
    }

    public shouldComponentUpdate(nextProps: IProps): boolean {
        return (
            this.props.cancelFn !== nextProps.cancelFn ||
            this.props.children !== nextProps.children ||
            this.props.name !== nextProps.name ||
            this.props.successFn !== nextProps.successFn ||
            this.props.size !== nextProps.size
        )
    }

    public componentDidMount(): void {
        document.body.classList.add('modal-open')
    }

    public componentWillUnmount(): void {
        document.body.classList.remove('modal-open')
    }

    public render(): React.ReactElement<HTMLElement> {
        // The padding size is the px amount of the modal padding, if the design changes, this should change as well.
        const paddingSize = 40 * 2
        const stylesWithPadding = {
            height: (this.props.size && this.props.size.height) ? this.props.size.height + paddingSize + 'px' : null,
            width: (this.props.size && this.props.size.width) ? this.props.size.width + paddingSize + 'px' : null
        }
        const contentStyles = {
            height: (this.props.size && this.props.size.height) ? this.props.size.height + 'px' : null,
            width: (this.props.size && this.props.size.width) ? this.props.size.width + 'px' : null
        }

        return (
            <div
                id='modal-ad'
                className='uk-modal uk-open'
                uk-modal='uk-modal'
                role='dialog'
                style={{ display: 'block' }}
                onClick={this.handleOutsideClick}
            >
                <div className='uk-modal-dialog' role='document' ref={this.getElementRef} style={stylesWithPadding}>
                    <div className='uk-height-1-1'>
                        <div>
                            <h3 className='uk-flex uk-flex-between uk-padding-small uk-padding-remove-vertical'>
                                <Translate message={this.props.name} />
                                <Button
                                    clickHandler={this.handleCancel}
                                    className='close uk-flex-right uk-padding-remove'
                                >
                                    <FontAwesomeIcon icon='close' />
                                </Button>
                            </h3>
                        </div>
                        <div className='uk-margin-auto uk-width-1-1 uk-height-1-1 uk-padding-remove-vertical' style={contentStyles}>
                            {this.props.body}
                        </div>
                        <div className={classNameHelper({ 'modal-footer': true, 'uk-hidden': typeof this.props.successFn !== 'function' })}>
                            <div className='modal-actions'>
                                {this.getCancelButton()}
                                {(this.props.successFn) && (this.getConfirmButton())}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private getConfirmButton() {
        return (
            <Button className='btn-success' clickHandler={this.handleConfirm}>
                <Translate message='CONFIRM' />
            </Button>
        )
    }

    private getCancelButton() {
        const blockClass = (!this.props.successFn) ? ' btn-block' : ''

        return (
            <Button className={ButtonClassType.DEFAULT + blockClass} clickHandler={this.handleCancel}>
                <Translate message='CLOSE' />
            </Button>
        )
    }

    private handleOutsideClick(ev: React.MouseEvent<HTMLElement>): void {
        ev.preventDefault()

        if (
            this.modalDialog !== ev.target &&
            !this.modalDialog.contains(ev.target as Node)
        ) {
            this.props.cancelFn()
        }
    }

    private handleCancel(ev: React.MouseEvent<HTMLElement>): void {
        ev.preventDefault()

        this.props.cancelFn()
    }

    private handleConfirm(ev: React.MouseEvent<HTMLElement>): void {
        ev.preventDefault()

        this.props.successFn()
    }

    private getElementRef(el: HTMLElement): void {
        this.modalDialog = el
    }
}

function mapStateToProps(state: IDataState): IProps {
    const modalState = state.components.modal

    return {
        body: (modalState) ? modalState.children : null,
        cancelFn: (modalState) ? modalState.cancelFn : null,
        name: (modalState) ? modalState.name : null,
        size: (modalState) ? modalState.size : null,
        successFn: (modalState) ? modalState.successFn : null
    }
}

export default connect(mapStateToProps)(ModalRoot)
