import { LOGIN } from './constants'
import { ILoginAction, IState } from './typings'

export default function reduce(
    state: IState = {
        loggedIn: false,
        time: -1
    },
    action: ILoginAction
): IState {
    switch (action.type) {
        case LOGIN:
            return {
                loggedIn: action.loggedIn,
                time: action.time
            }
    }

    return state
}
