import { data, user } from 'lib/store'
import { PermissionType } from 'services/oauth/constants'
import { LOGIN } from './constants'
import { fetchUser } from './services/index'
import { hasDaaPermission } from 'services/user'

export async function login(loggedIn: boolean, isEvent: boolean = false): Promise<void> {
    if (loggedIn && !isEvent) {
        await fetchUser()
    }

    data.dispatch({
        loggedIn,
        time: Date.now(),
        type: LOGIN
    })

    // tslint:disable-next-line:no-string-literal
    if (window['ADVANCEDSTORE_MAGICTAG']) {
        const userState = user.getState()
        // tslint:disable-next-line:no-string-literal no-unsafe-any
        window['ADVANCEDSTORE_MAGICTAG'].dispatch(
            3,
            {
                email: userState.user.data.username,
                firstname: userState.user.data.firstname,
                id: 19,
                lastname: userState.user.data.lastname,
                name: userState.user.data.firstname + ' ' + userState.user.data.lastname,
                userType: (hasDaaPermission('users', PermissionType.Write) && hasDaaPermission('rtb_flights', PermissionType.Write))
                    ? 'admin'
                    : hasDaaPermission('advertisers', PermissionType.Write)
                        ? 'advertiser'
                        : hasDaaPermission('publishers', PermissionType.Write)
                            ? 'publisher'
                            : 'unkown'
            }
        )
    }
}
